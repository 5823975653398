<template>
	<div>
		<v-navigation-drawer
			app
			:bottom="$vuetify.breakpoint.sAndDown"
			v-model="rightDrawer"
			right
			clipped
			width="350"
			:mini-variant.sync="mini"
			permanent
		>
			<v-list-item v-if="!mini"
				><v-btn @click.stop="mini = !mini" tile text block color="secondary">
					Fenster schließen
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn></v-list-item
			>
			<v-list-item>
				<v-list-item-avatar>
					<v-icon color="secondary">mdi-domain</v-icon>
				</v-list-item-avatar>

				<v-list-item-content v-if="getActiveClient">
					<v-list-item-title>{{ getActiveClient.company }}</v-list-item-title>
					<v-list-item-subtitle>{{ getActiveClient.rechtsform.name }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item>
				<v-list-item-avatar>
					<v-icon color="secondary">mdi-card-account-mail</v-icon>
				</v-list-item-avatar>

				<v-list-item-content v-if="getActiveClient">
					<v-list-item-title>{{ getActiveClient.name }}</v-list-item-title>
					<v-list-item-subtitle>{{ getActiveClient.email }}</v-list-item-subtitle>
					<v-list-item-subtitle>{{ getActiveClient.tel }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item>
				<v-list-item-avatar>
					<v-icon color="secondary">mdi-timeline</v-icon>
				</v-list-item-avatar>
				<v-list-item-title>Timeline</v-list-item-title>
			</v-list-item>
			<TimeLine v-if="!mini" />
			<!-- <v-btn @click="test">test</v-btn> -->
		</v-navigation-drawer>
	</div>
</template>

<script>
import TimeLine from "@/components/Timeline"
import { mapGetters } from "vuex"
export default {
	methods: {
		toggleDrawer() {
			this.mini = !this.mini
		},
		test() {
			console.log(this.mini)
		},
	},
	data() {
		return {
			rightDrawer: true,
			mini: true,
		}
	},
	components: {
		TimeLine,
	},
	computed: {
		...mapGetters(["getActiveClient"]),
	},
}
</script>

<template>
	<div>
		<!-- <v-btn @click="test">Test</v-btn>
		<v-btn @click="test2">Test2</v-btn> -->
		<v-card height="500px" class="scrollable" outlined v-if="getActiveClient" id="chatCard">
			<v-card-text>
				<div
					class="mb-6 wrapper"
					v-for="(message, index) in messages"
					:key="index"
					:class="getClassForChatDisplay(message.username) ? 'own' : 'other'"
				>
					<div class="caption author px-4" :class="getClassForChatDisplay(message.username) ? 'own' : 'other'">
						{{ message.username }} - {{ formatTime(message.date) }}
					</div>
					<div class="pt-0 d-flex chatMessage" :class="getClassForChatDisplay(message.username) ? 'own' : 'other'">
						<div :class="getClassForChatDisplay(message.username) ? 'own' : 'other'">
							<v-card
								outlined
								min-width="300px"
								max-width="500px"
								class="messageCard"
								:class="getClassForChatDisplay(message.username) ? 'own' : 'other'"
							>
								<v-card-text
									class="messageContent"
									:class="getClassForChatDisplay(message.username) ? 'own' : 'other'"
								>
									{{ message.content }}
								</v-card-text>
							</v-card>
						</div>
					</div>
				</div>
			</v-card-text>
		</v-card>
		<v-row>
			<v-col cols="8">
				<v-textarea
					outlined
					v-model="content"
					:loading="isLoading"
					@keyup.shift.enter="sendMessage"
					label="Neue Nachricht..."
					hint="'Shift-Enter' zum direkten versenden"
					rows="3"
					clearable
				></v-textarea>
			</v-col>
			<v-col cols="2">
				<v-btn @click="sendMessage" :loading="isLoading" color="secondary">Senden</v-btn>
			</v-col>
		</v-row>
		<v-btn @click="test">Chattest</v-btn>
	</div>
</template>
<script>
import { mapGetters } from "vuex"
import firebase from "firebase"
export default {
	props: {
		client: String,
	},
	mounted() {
		if (this.getActiveClient == null) {
			//
		} else {
			this.loadChat()
			this.mountElem()
		}
	},
	methods: {
		async sendMessage() {
			if (this.content) {
				let now = Date()
				let payload = {
					username: this.getCurrentUser.name.firstName + " " + this.getCurrentUser.name.lastName,
					content: this.content,
					date: now,
					usertype: this.getCurrentUser.type,
					chatID: this.getActiveClient.companySlug,
				}
				// console.log(payload)
				this.isLoading = true
				await this.$store.dispatch("sendMessage", payload)
				this.isLoading = false
				this.content = ""
			}
		},

		async mountElem() {
			await this.$nextTick()
			this.elem = document.getElementById("chatCard")

			this.elem.scrollTop = this.elem.scrollHeight
		},

		getClassForChatDisplay(user) {
			let username = this.getCurrentUser.name.firstName + " " + this.getCurrentUser.name.lastName
			if (user == username) return true
			return false
		},

		test() {
			console.log(document.getElementById("chatCard").scroll)
		},
		test2() {
			console.log(this.chatMessages)
			console.log(this.messages)
		},

		loadChat() {
			if (this.getActiveClient != null) {
				// let msg = this
				this.chatMessages = []
				let chatID = this.getActiveClient.companySlug
				this.chatRef = firebase
					.database()
					.ref("messages")
					.child(chatID)
					.child("messages")
					.limitToLast(10)
				this.chatRef.on("child_added", (snapshot) => {
					let message = snapshot.val()
					this.chatMessages.push(message)
					this.mountElem()
				})
			}
		},

		formatTime(timestamp) {
			let date = new Date(timestamp)

			let newDate = date.toLocaleDateString("de-DE", {
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
			})
			return newDate
		},
	},
	data() {
		return {
			content: null,
			isLoading: false,
			chatMessages: [],
			chatRef: null,
			user: [],
			elem: null,
		}
	},

	computed: {
		...mapGetters(["getCurrentUser", "getActiveClient"]),
		messages() {
			return this.chatMessages
		},

		getFullName() {
			return this.getCurrentUser.name.firstName + " " + this.getCurrentUser.name.lastName
		},
	},
}
</script>
<style scoped>
.messageCard.own {
	background-color: var(--v-primary-lighten1);
}

.messageCard.other {
	background-color: rgb(214, 214, 214);
}

.messageContent.own {
	color: white;
}

.messageContent.other {
	color: black;
}

.author.own {
	display: flex;
	justify-content: flex-end;
}

.chatMessage.own {
	justify-content: flex-end;
}

.scrollable {
	overflow-y: scroll;
}
</style>

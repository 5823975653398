<template>
	<v-card flat>
		<v-card-title class="text-center justify-center">
			<h2>Deine Mandanten</h2>
		</v-card-title>
		<v-row dense>
			<v-col md="5" lg="3" sm="6">
				<v-card dense outlined>
					<v-expansion-panels flat hover title>
						<v-expansion-panel>
							<v-expansion-panel-header class="subtitle-2">
								<div>
									<v-icon color="secondary" left>mdi-filter</v-icon>
									<span>Filter Optionen...</span>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-text-field
									v-model="search"
									prepend-icon="mdi-magnify"
									label="Filter Mandanten..."
									single-line
									hide-details
									class="body-2"
									dense
									color="secondary"
								></v-text-field>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-card>
			</v-col>
		</v-row>

		<v-data-table
			:items="getClientList"
			:headers="headers"
			hide-default-footer
			:loading="isLoading"
			loading-text="Lese Mandantendaten..."
			no-data-text="Bisher keine Mandanten angelegt"
			:search="search"
		>
			<template v-slot:top>
				<v-row>
					<v-col align-self="center" class="d-flex justify-end">
						<v-btn color="secondary" elevation="10" @click="addClient">
							<v-icon left>mdi-plus-circle</v-icon>
							Mandanten hinzufügen
						</v-btn>
					</v-col>
				</v-row>
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<v-chip :color="getStatus(item.taxwizard).color" label small>
					<v-icon small left>{{ getStatus(item.taxwizard).icon }}</v-icon>
					{{ getStatus(item.taxwizard).status }}
				</v-chip>
			</template>
			<template v-slot:[`item.active`]="{ item }">
				<v-chip color="primary" outlined @click="activateClient(item)" v-if="checkActive(item)">
					<v-icon left small color="secondary">mdi-chevron-up-circle</v-icon>
					Aktivieren
				</v-chip>
				<v-chip color="success" v-if="!checkActive(item)" outlined>
					<v-icon left small>mdi-check</v-icon>
					Aktiver Mandant
				</v-chip>
			</template>
			<template v-slot:[`item.company`]="{ item }">
				<strong>{{ item.company }}</strong>
				<a @click.prevent="clientDetails(item)" class="caption secondary--text" v-if="false">
					- Details
				</a>
				<p class="caption mb-0 primary--text">
					{{ item.rechtsform.abbr }}
				</p>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip top color="secondary">
					<template v-slot:activator="{ on }">
						<v-icon @click="action(item)" v-on="on">
							mdi-pencil
						</v-icon>
					</template>
					Editieren
				</v-tooltip>
				<v-tooltip top color="secondary">
					<template v-slot:activator="{ on }">
						<v-icon @click="activateClient(item)" v-on="on" color="success">
							mdi-account-reactivate
						</v-icon>
					</template>
					Mandant aktivieren
				</v-tooltip>
			</template>
		</v-data-table>
		<v-divider class="mb-10"></v-divider>
		<AddClient ref="addClientDialog" @addedClient="addedClient" />
		<v-snackbar v-model="snackbar.status" :timeout="snackbar.timeout" color="success">
			<v-icon>mdi-check</v-icon>
			{{ snackbar.text }}
		</v-snackbar>
		<!-- <v-btn @click="test">TEST BUTTON</v-btn> -->
	</v-card>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"
import AddClient from "@/components/AddClient"
export default {
	mounted() {
		if (this.userExists) {
			if (this.clientList.length < 1) {
				this.fetchAllClients(this.getCurrentUser.userID)
			}
		}
		this.$cookies.set("lastVisit", new Date(), "1y")
	},
	watch: {
		userProfile: function() {
			if (this.clientList.length > 0) {
				// bereits vorhanden, mach nix
			} else {
				this.fetchAllClients()
			}
		},
	},

	methods: {
		async fetchAllClients(userID) {
			this.isLoading = true
			await this.$store.dispatch("fetchAllClients", userID)
			this.isLoading = false
		},

		getStatus(status) {
			var newStatus = {
				status: null,
				color: null,
				icon: null,
			}
			if (status.finished) {
				newStatus.status = "Fertig"
				newStatus.color = "success"
				newStatus.icon = "mdi-check-bold"
				return newStatus
			}
			if (status.started) {
				newStatus.status = "In Bearbeitung"
				newStatus.color = "yellow"
				newStatus.icon = "mdi-progress-clock"
				return newStatus
			}
			newStatus.status = "Offen"
			newStatus.color = "error"
			newStatus.icon = "mdi-alert-circle-outline"
			return newStatus
		},

		checkActive(client) {
			if (this.getActiveClient) {
				if (client.company === this.getActiveClient.company) {
					return false
				}
			}
			return true
		},

		closeDialog() {
			this.dialog = !this.dialog
		},

		action(item) {
			console.log("KLICK: ", item)
		},

		activateClient(item) {
			// this.$router.push({
			//   name: "ClientDetails",
			//   params: {
			//     client_slug: item.companySlug,
			//   },
			// })
			this.$store.dispatch("updateLastAktiveClient", item)
			this.$store.commit("clearFileList")
			this.$store.commit("clearTimeline")
			this.$store.dispatch("getTimelineForActiveClient", item)
			this.snackbar.status = false
			this.snackbar.text = "Aktiver Mandant geändert auf: " + item.company
			this.snackbar.timeout = 4000
			this.snackbar.status = true
		},

		// eslint-disable-next-line no-unused-vars
		clientDetails(item) {
			// this.$router.push({
			// 	name: "ClientDetails",
			// 	params: {
			// 		client_slug: item.companySlug,
			// 	},
			// })
		},

		addClient() {
			this.$refs.addClientDialog.toggleDialog()
		},

		addedClient() {
			this.snackbar.text = "Mandant erfolgreich angelegt"
			this.snackbar.status = true
			this.snackbar.timeout = 4000
		},

		test() {
			console.log(this.getClientList)
		},
	},
	data() {
		return {
			headers: [
				{
					text: "Unternehmen",
					value: "company",
					class: "secondary--text",
				},
				{
					text: "Aktivieren",
					value: "active",
					class: "secondary--text",
				},
				{
					text: "Kontakt",
					value: "name",
					class: "secondary--text",
				},

				{
					text: "Status Einladung",
					value: "status",
					class: "secondary--text",
				},
				{
					text: "Externe ID",
					value: "externalId",
					class: "secondary--text",
				},
				{
					text: "Aktionen",
					value: "actions",
					class: "secondary--text",
				},
			],
			isLoading: false,
			search: null,
			dialog: null,
			snackbar: {
				status: false,
				text: "",
				timeout: 4000,
			},
		}
	},
	computed: {
		...mapState(["userProfile", "clientList"]),
		...mapGetters(["getCurrentUser", "getClientList", "getActiveClient"]),

		userExists() {
			return Object.keys(this.getCurrentUser).length > 1
		},
	},

	components: { AddClient },
}
</script>

/* eslint-disable no-unused-vars */
import firebase from "firebase"
import "firebase/firestore"
import "firebase/auth"

const config = {
	apiKey: "AIzaSyA3v_MeUiOZo7ux46Oa7MzZ5yChG1RMA9o",
	authDomain: "codename-puki.firebaseapp.com",
	projectId: "codename-puki",
	databaseURL: "https://codename-puki-default-rtdb.europe-west1.firebasedatabase.app",
	storageBucket: "codename-puki.appspot.com",
	messagingSenderId: "672433991429",
	appId: "1:672433991429:web:04c31122310cb359f7516c",
}

const firebaseApp = firebase.initializeApp(config)
const timestamp = firebaseApp.firestore().settings({ timestampsInSnapshots: true })

const projectAuth = firebase.auth()
const firestore = firebase.firestore()
const storage = firebase.storage()
const increment = firebase.firestore.FieldValue.increment(1)
const arrayUnion = firebase.firestore.FieldValue.arrayUnion
// const dateCalc = firebase.firestore.Timestamp

// const auth = firebase.auth()
// const db = firebase.firestore()

// export {
//   db,
//   auth
// }

export { firestore, projectAuth, timestamp, storage, increment, arrayUnion }

//   // utils
// const db = firebase.firestore()
// const auth = firebase.auth()

// // collection references
// const usersCollection = db.collection('users')

// // export utils/refs
// export {
//     db,
//     auth,
//     usersCollection,
//   }

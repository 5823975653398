<template>
	<v-card elevation="0" v-if="getActiveClient" flat>
		<v-card-title class="text-center justify-center">
			<h2>{{ this.getActiveClient.company }}</h2>
		</v-card-title>
		<v-card-subtitle class="text-center justify-center">
			Kontakt: {{ this.getActiveClient.name }}
		</v-card-subtitle>
		<v-divider></v-divider>
		<v-tabs v-model="activeTab" grow slider-color="secondary" active-class="secondary--text" show-arrows>
			<v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
				<v-icon left>{{ tab.icon }}</v-icon>
				{{ tab.name }}
			</v-tab>
			<v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route">
				<v-card flat max-height="800px" style="overflow: auto" id="wrapCard"><router-view></router-view></v-card>
			</v-tab-item>
		</v-tabs>
		<!-- <v-btn @click="test">Test</v-btn> -->
	</v-card>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState, mapGetters } from "vuex"
import ChatCmp from "@/components/chat/ChatCmp.vue"
export default {
	methods: {
		async fetchClient() {
			this.currentClient = await this.$store.dispatch("fetchClient", this.$route.params.client_slug)
		},

		test() {
			console.log(this.activeTab)
		},
	},
	data() {
		return {
			// tab: [],
			activeTab: `/clientdetails/${this.$route.params.client_slug}`,

			tabs: [
				{
					id: 1,
					name: "Übersicht",
					icon: "mdi-view-dashboard-outline",
					route: `/clientdetails/${this.$route.params.client_slug}`,
				},
				{
					id: 2,
					name: "Steuerwizard",
					icon: "mdi-wizard-hat",
					route: `/clientdetails/${this.$route.params.client_slug}/steuerwizard`,
				},
				{
					id: 3,
					name: "Belegmanager",
					icon: "mdi-wizard-hat",
					route: `/clientdetails/${this.$route.params.client_slug}/files`,
				},
				{
					id: 4,
					name: "Timeline",
					icon: "mdi-timeline",
					route: `/clientdetails/${this.$route.params.client_slug}/timeline`,
				},
			],
			route: `/clientdetails/${this.$route.params.client_slug}`,
		}
	},
	computed: {
		...mapState(["userProfile", "activeClient"]),
		...mapGetters(["getCurrentUser", "getActiveClient"]),
	},

	components: { ChatCmp },
}
</script>

<style scoped>
.scrollable {
	overflow-y: scroll;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('AddFile',{ref:"addFileDialog",on:{"addedFile":_vm.addedFile}}),_c('EditFile',{ref:"editFileDialog",on:{"updateClient":_vm.updateFileAfterEdit}}),_c('FileComments',{ref:"commentDialog"}),(_vm.getActiveClient)?_c('v-card-title',[_vm._v("Belegmanager - "+_vm._s(_vm.getActiveClient.company))]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Dateisuche...","dense":"","outlined":"","clearable":"","color":"secondary","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-start",attrs:{"align-self":"center","cols":"4"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-calendar-range","items":_vm.yearsForFilter,"attach":"","chips":"","label":"Jahresfilter","outlined":"","dense":"","clearable":"","color":"secondary","small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"secondary","small":""}},[_c('strong',[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.filterYears),callback:function ($$v) {_vm.filterYears=$$v},expression:"filterYears"}})],1)],1),_c('v-data-table',{key:_vm.rerender,attrs:{"items":_vm.filteredItems,"item-key":"filename","headers":_vm.headers,"items-per-page":-1,"search":_vm.search,"loading":_vm.isLoading,"hide-default-footer":"","group-by":"categories","loading-text":"Lese Dateien...","no-data-text":"Bisher keine Dateien gefunden"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"align-self":"center"}},[_c('v-btn',{attrs:{"color":"secondary","elevation":"10","loading":_vm.isLoading},on:{"click":_vm.addFile}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_vm._v(" Dateien hinzufügen ")],1)],1)],1)]},proxy:true},{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{staticClass:"primary secondary--text font-weight-bold",attrs:{"colspan":headers.length}},[_c('v-icon',{attrs:{"color":"secondary","small":"","left":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? "mdi-minus-circle" : "mdi-plus-circle")+" ")]),_vm._v(" "+_vm._s(group.toUpperCase())+" ")],1)]}},{key:"item.filename_original",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.filename_original)+" "),_c('p',{staticClass:"caption mb-0 secondary--text"},[_c('v-icon',{attrs:{"small":"","color":"secondary"}},[_vm._v("mdi-upload")]),_vm._v(" "+_vm._s(item.uploadedBy.name.firstName)+" "+_vm._s(item.uploadedBy.name.lastName)+" - "+_vm._s(_vm.formatTime(item.uploadedWhen))+" ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","loading":_vm.isLoading}},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editFile(item)}}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","loading":_vm.isLoading}},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.downloadFile(item)}}},[_vm._v(" mdi-download ")])],1),_c('v-btn',{attrs:{"icon":"","loading":_vm.isLoading}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","loading":_vm.isLoading}},[_c('v-icon',{attrs:{"color":item.comments.length > 0 ? 'secondary' : 'primary'},on:{"click":function($event){return _vm.commentFile(item)}}},[_vm._v(" mdi-comment-outline ")])],1),_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(item.comments.length))])]}}],null,true),model:{value:(_vm.selectedFiles),callback:function ($$v) {_vm.selectedFiles=$$v},expression:"selectedFiles"}}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mt-12",attrs:{"icon":""},on:{"click":_vm.fetchAllFiles}},[_c('v-icon',{attrs:{"color":"secondary","large":""}},[_vm._v(" mdi-reload ")])],1)],1)],1),_c('v-snackbar',{staticClass:"position: relative",attrs:{"timeout":"4000","color":"success","top":"","transition":"scroll-y-transition"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" Datei editiert ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
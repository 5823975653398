/* eslint-disable no-unused-vars */
import filesActions from "./actions.js"
import filesMutations from "./mutations.js"
import filesGetters from "./getters.js"

export default {
	state() {
		return {
			fileList: null,
			commentList: null,
			filesLastVisit: [],
		}
	},
	mutations: filesMutations,
	actions: filesActions,
	getters: filesGetters,
}

<template>
	<div>
		<v-card flat v-if="type == 'Gewerbesteuer'">
			<v-card-title>{{ type }} {{ year }}</v-card-title>
			<GStCmp v-if="!isTypeStb" :activeClient="getActiveClient" :currentUser="getCurrentUser" />
		</v-card>
		<v-card flat v-if="type != 'Gewerbesteuer'">
			<v-card-title>{{ type }} {{ year }}</v-card-title>
			<v-card-text>{{ type }} kann bald bearbeitet werden</v-card-text>
		</v-card>
	</div>
</template>

<script>
import GStCmp from "@/components/taxwizard/Gewerbesteuer.vue"
import { mapGetters } from "vuex"
export default {
	methods: {
		test() {
			console.log(this.getActiveClient)
		},
	},
	data() {
		return {
			year: this.$route.params.year,
			type: this.$route.params.type,
			slug: this.$route.params.companyId,
			tlink: this.$route.params.link,
		}
	},
	components: { GStCmp },

	computed: {
		...mapGetters(["isTypeStb", "getActiveClient", "getCurrentUser"]),
	},
}
</script>

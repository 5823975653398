/* eslint-disable no-unused-vars */
import firebase from "firebase"

export default {
	sendMessage(context, payload) {
		let chatID = payload.chatID
		const message = {
			username: payload.username,
			content: payload.content,
			date: payload.date,
			usertype: payload.usertype,
		}
		console.log(message)
		firebase
			.database()
			.ref("messages")
			.child(chatID)
			.child("messages")
			.push(message)
			.then((data) => {
				// Erfolgreich
			})
			.catch((error) => {
				console.log("Etwas ist schief gelaufen")
			})
	},
}

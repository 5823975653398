<template>
	<div>
		<ClientHome v-if="!isTypeStb" />
		<StbDialog ref="statusdialog" />
		<v-card min-height="400px" v-if="isTypeStb" flat>
			<v-card-title>Übersicht Steuer Wizard</v-card-title>
			<v-card-text>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus iste doloribus dignissimos
				blanditiis aperiam ipsam, qui reiciendis ex dolor alias!
			</v-card-text>
			<v-divider class="my-2"></v-divider>
			<v-card-text>
				<v-tabs centered v-model="tab" grow slider-color="secondary" active-class="secondary--text" show-arrows>
					<v-tab>
						<v-icon left>mdi-wizard-hat</v-icon>
						Übersicht Mandanten
					</v-tab>
					<v-tab>
						<v-icon left>mdi-file-edit</v-icon>
						Vorlagen verwalten
					</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-card flat outlined min-height="300px">
							<v-card-text>
								<v-row>
									<v-col cols="12" lg="6" md="8">
										<v-text-field
											outlined
											color="secondary"
											dense
											label="Filtere Mandanten..."
											prepend-icon="mdi-magnify"
											v-model="search"
											clearable
										></v-text-field>
									</v-col>
								</v-row>

								<v-data-iterator
									:items="getClientList"
									hide-default-footer
									no-data-text="Bisher keine Daten im System gefunden"
									:search="search"
									no-results-text="Keine passenden Mandanten gefunden"
								>
									<template v-slot:default="props">
										<v-row>
											<v-col cols="12" sm="6" md="6" lg="6" v-for="prop in props.items" :key="prop.company">
												<v-card height="450px">
													<v-card-title class="subtitle-1">
														<v-icon left color="primary">mdi-domain</v-icon>
														{{ prop.company }} {{ prop.rechtsform.abbr }}
													</v-card-title>
													<v-divider class="secondary"></v-divider>
													<v-card-text>
														Aktive Steuerwizards - Anzahl: {{ prop.categories.length }}
														<!-- <v-data-table :items="prop.categories" dense :headers="headers">
															<template v-slot:[`item.status_client`]="{ item: childItem }">
																<v-edit-dialog
																	:return-value.sync="childItem.status"
																	@save="test(childItem, prop)"
																	@cancel="test"
																>
																	{{ childItem.status }}
																	<template v-slot:input>
																		<div class="mt-4 subheader">
																			Status ändern
																		</div>
																		<v-text-field v-model="childItem.status" outlined></v-text-field>
																	</template>
																</v-edit-dialog>
															</template>
															<template v-slot:[`item.wizard`]="{ item: childItem }">
																{{ childItem.name }}
															</template>
														</v-data-table> -->
														<v-list dense three-line max-height="300px" style="overflow-y: auto">
															<v-list-item
																v-for="(wizard, id) in prop.categories"
																:key="id"
																:value="wizard.taxwizard_link"
																inactive
															>
																<v-list-item-icon>
																	<v-icon>mdi-wizard-hat</v-icon>
																</v-list-item-icon>
																<v-list-item-content>
																	<v-list-item-title>{{ wizard.name }} {{ wizard.year }}</v-list-item-title>
																	<v-list-item-subtitle class="caption">
																		Status Mandant:
																		<v-chip x-small :color="getStatus(wizard.status_client.id).color">
																			{{ getStatus(wizard.status_client.id).text }}
																		</v-chip>
																	</v-list-item-subtitle>
																	<v-list-item-subtitle class="caption">
																		Status StB:
																		<v-chip x-small :color="getStatus(wizard.status_stb.id).color" @click="test(prop, wizard)">
																			{{ getStatus(wizard.status_stb.id).text }}
																		</v-chip>
																	</v-list-item-subtitle>
																</v-list-item-content>
															</v-list-item>
														</v-list>
													</v-card-text>
												</v-card>
											</v-col>
										</v-row>
									</template>
								</v-data-iterator>
								<!-- <v-data-table
									:items="getClientList"
									:headers="headers"
									hide-default-footer
									disable-sort
									dense
									no-data-text="Bisher keine Steuerwizards im System angelegt"
								>
									<template v-slot:[`item.categories`]="{ item }">
										<v-list>
											<v-list-item v-for="(i, id) in item.categories" :key="id">{{ i.name }} {{ i.year }}</v-list-item>
										</v-list>
									</template>
								</v-data-table> -->
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
			<v-btn @click="test">test</v-btn>
			<v-btn @click="test2">test</v-btn>
		</v-card>
	</div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex"
import GStCmp from "@/components/taxwizard/Gewerbesteuer.vue"
import ClientHome from "@/components/taxwizard/ClientHome.vue"
import StbDialog from "@/components/taxwizard/StbStatusDialog.vue"
export default {
	methods: {
		test(item, wizard) {
			this.$refs.statusdialog.toggleDialog()
			this.$refs.statusdialog.passItem(item, wizard)
		},

		test2(item) {
			console.log(this.getClientList)
		},

		fetchAllClients() {
			if (this.getClientList.length < 1) {
				this.$store.dispatch("fetchAllClients", this.getCurrentUser.userID)
			}
		},

		editWizard(item, id) {},

		getStatus(status) {
			let result
			switch (status) {
				case 1:
					return (result = {
						color: "primary",
						text: "Offen",
						tooltip: "Der Mandant kann den Steuer Wizard bearbeiten",
					})
				case 2:
					return (result = {
						color: "yellow",
						text: "In Bearbeitung",
						tooltip: "Der Mandant bearbeitet den Steuer Wizard, ist aber noch nicht fertig",
					})
				case 3:
					return (result = {
						color: "green",
						text: "Fertig",
						tooltip: "Der Mandant hat den Steuerwizard fertig gestellt",
					})

				default:
					return (result = {
						color: "grey",
						text: "Nicht begonnen",
					})
			}
		},
	},
	data() {
		return {
			tab: [],
			editOptions: [
				{
					name: "Offen",
					value: "open",
				},
				{
					name: "In Bearbeitung",
					value: "In progress",
				},
			],
			search: null,
			menu: null,
			headers: [
				{
					text: "Steuerart",
					value: "wizard",
				},
				{
					text: "Mandant",
					value: "status_client",
				},
				{
					text: "StB",
					value: "status_stb",
				},
			],
		}
	},

	computed: {
		...mapGetters(["isTypeStb", "getClientList", "getCurrentUser"]),
	},

	components: { GStCmp, ClientHome, StbDialog },

	mounted() {
		// if (this.getClientList.length < 1) {
		// 	this.fetchAllClients(this.getCurrentUser.userID)
		// }
		this.fetchAllClients()
		this.$cookies.set("lastVisit", new Date(), "1y")
	},
}
</script>

<template>
	<div>
		<v-row>
			<v-col xs="12" sm="12" lg="6" md="6">
				<v-card>
					<v-card-title class="subtitle-2">
						Allgemeine Informationen
						<v-spacer></v-spacer>
						<v-icon small>mdi-pencil</v-icon>
					</v-card-title>
					<v-divider class="secondary"></v-divider>
					<v-card-text>
						<v-row>
							<v-col md="6" lg="6" sm="12" xs="12">
								Firmenname
								<br />
								<span class="font-weight-bold">{{ getActiveClient.company }}</span>
							</v-col>
							<v-col md="6" lg="6" sm="12" xs="12">
								Rechtsform
								<br />
								<span class="font-weight-bold">{{ getActiveClient.rechtsform.abbr }}</span>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="6" lg="6" sm="12" xs="12">
								Externe ID (z.B. DATEV)
								<br />
								<span class="font-weight-bold">{{ getActiveClient.externalId }}</span>
							</v-col>
							<v-col md="6" lg="6" sm="12" xs="12" v-if="getActiveClient.taxwizard.finished_when">
								Angemeldet am:
								<br />
								<span class="font-weight-bold">{{ formatTime(getActiveClient.taxwizard.finished_when) }}</span>
							</v-col>
							<v-col md="6" lg="6" sm="12" xs="12" v-else>
								Angemeldet am:
								<br />
								<span class="font-weight-bold error--text">Bisher nicht angemeldet</span>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col xs="12" sm="12" lg="6" md="6" v-if="getActiveClient.adresse">
				<v-card>
					<v-card-title class="subtitle-2">
						Anschrift
						<v-spacer></v-spacer>
						<v-icon small>mdi-pencil</v-icon>
					</v-card-title>
					<v-divider class="secondary"></v-divider>
					<v-card-text>
						<v-row>
							<v-col md="6" lg="6" sm="12" xs="12">
								Straße
								<br />
								<span class="font-weight-bold">{{ getActiveClient.adresse.str }}</span>
							</v-col>
							<v-col md="6" lg="6" sm="12" xs="12">
								Zusatz
								<br />
								<span class="font-weight-bold">{{ getActiveClient.adresse.zusatz }}</span>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="6" lg="6" sm="12" xs="12">
								PLZ
								<br />
								<span class="font-weight-bold">{{ getActiveClient.adresse.PLZ }}</span>
							</v-col>
							<v-col md="6" lg="6" sm="12" xs="12">
								Ort
								<br />
								<span class="font-weight-bold">{{ getActiveClient.adresse.ort }}</span>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col xs="12" sm="12" lg="6" md="6" v-else>
				<v-card>
					<v-card-title class="subtitle-2">
						Anschrift
						<v-spacer></v-spacer>
						<v-icon small>mdi-pencil</v-icon>
					</v-card-title>
					<v-divider class="secondary"></v-divider>
					<v-card-text>
						Mandant hat bisher keine Daten geliefert
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card>
					<v-card-title class="subtitle-2">Kontaktinformationen</v-card-title>
					<v-divider class="secondary"></v-divider>
					<v-card-text>
						<v-row>
							<v-col md="4" lg="4" sm="12" xs="12">
								Name des Kontakts
								<br />
								<span class="font-weight-bold">{{ getActiveClient.name }}</span>
							</v-col>
							<v-col md="4" lg="4" sm="12" xs="12">
								E-Mail Adresse
								<br />
								<span class="font-weight-bold">{{ getActiveClient.email }}</span>
							</v-col>
							<v-col md="4" lg="4" sm="12" xs="12">
								Telefon
								<br />
								<span class="font-weight-bold">{{ getActiveClient.tel }}</span>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card>
					<v-card-title class="subtitle-1 secondary--text">
						Steuerwizard
						<v-spacer></v-spacer>
						<span class="subtitle-2 primary--text">Details</span>
					</v-card-title>
					<v-divider class="secondary"></v-divider>
					<v-card-text>
						<v-row>
							<v-col md="4" lg="3" sm="6" xs="12" class="text-center">
								Anzahl Steuer Wizards
								<br />
								<span class="font-weight-bold">
									<h1 class="my-4 secondary--text">{{ getActiveClient.categories.length }}</h1>
								</span>
							</v-col>
							<v-col md="4" lg="3" sm="6" xs="12" class="text-center">
								Offen
								<br />
								<span class="font-weight-bold">
									<h1 class="my-4 secondary--text">{{ getStatusNumber("open") }}</h1>
								</span>
							</v-col>
							<v-col md="4" lg="3" sm="6" xs="12" class="text-center">
								In Bearbeitung
								<br />
								<span class="font-weight-bold">
									<h1 class="my-4 secondary--text">{{ getStatusNumber("in progress") }}</h1>
								</span>
							</v-col>
							<v-col md="4" lg="3" sm="6" xs="12" class="text-center">
								Finalisiert
								<br />
								<span class="font-weight-bold">
									<h1 class="my-4 secondary--text">{{ getStatusNumber("finished") }}</h1>
								</span>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<!-- <v-card-text>
			<v-card-subtitle>Allgemeine Informationen</v-card-subtitle>
			<v-row>
				<v-col cols="6">
					<p>
						Firmenname:
						<strong>{{ getActiveClient.company }}</strong>
					</p>
					<p>
						Rechtsform:
						<strong>{{ getActiveClient.rechtsform.name }}</strong>
						({{ getActiveClient.rechtsform.abbr }})
					</p>
				</v-col>
				<v-col></v-col>
			</v-row>
		</v-card-text> -->
	</div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
	methods: {
		formatTime(timestamp) {
			let date
			if (!(timestamp instanceof Date)) {
				date = timestamp.toDate()
			} else {
				date = timestamp
			}

			//   let mm = date.getMonth() + 1
			//   let dd = date.getDate()
			//   let yyyy = date.getFullYear()

			let newDate = date.toLocaleDateString("de-DE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
			})

			//   date = dd + "." + mm + "." + yyyy
			return newDate
		},
		getStatusNumber(status) {
			let number = 0
			for (var i = 0; i < this.getActiveClient.categories.length; i++) {
				if (this.getActiveClient.categories[i].status == status) {
					number++
				}
			}
			return number
		},
	},
	data() {
		return {}
	},

	computed: {
		...mapGetters(["getCurrentUser", "getActiveClient"]),
	},
}
</script>

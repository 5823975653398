/* eslint-disable no-unused-vars */

import { firestore } from "@/firebase"
import { arrayUnion } from "@/firebase"

export default {
	async addTimelineEntry({ commit }, payload) {
		const timelineRef = await firestore.collection("timeline").doc(payload.companySlug)
		const checkExists = await timelineRef.get()
		if (!checkExists.exists) {
			let entries = []
			entries.push(payload.entry)
			await timelineRef.set({
				entries: entries,
			})
		} else {
			await timelineRef.update({
				entries: arrayUnion(payload.entry),
			})
		}

		commit("updateTimeline", payload.entry)
	},

	async getTimelineForActiveClient({ commit, getters }, payload) {
		if (payload != undefined) {
			if (getters.isTypeStb) {
				const timelineRef = firestore.collection("timeline").doc(payload.companySlug)
				const doc = await timelineRef.get()

				commit("setTimelineInState", doc.data())
			}
		}
	},
}

<template>
	<div>
		<v-card flat>
			<v-card-title class="subtitle-1">
				Nachrichten
			</v-card-title>
			<v-divider class="secondary"></v-divider>
			<v-card-text>
				<ChatCmp :client="this.$store.getters.getActiveClient.companySlug" />
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import ChatCmp from "@/components/chat/ChatCmp.vue"
export default {
	data() {
		return {}
	},
	components: { ChatCmp },
}
</script>

<template>
	<div>
		<!-- <UploadCmp /> -->
		<FileListCmp />
	</div>
</template>

<script>
import UploadCmp from "../components/files/UploadCmp"
import FileListCmp from "../components/files/FileListCmp"
export default {
	data() {
		return {}
	},
	components: { UploadCmp, FileListCmp },
	mounted() {
		this.$cookies.set("lastVisit", new Date(), "1y")
	},
}
</script>

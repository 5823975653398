import router from "@/router"
import { firestore } from "@/firebase"
import { projectAuth } from "@/firebase"

export default {
	// Wrapperfunktion für catch-Bug von Firebase --> Error wird gehandelt
	async signIn({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			projectAuth
				.signInWithEmailAndPassword(payload.email, payload.password)
				.then((userCreds) => {
					resolve(userCreds)
					dispatch("fetchCurrentUser", userCreds.user)
				})
				.catch((reason) => {
					reject(reason)
				})
		})
	},

	// Old signIn method, still in here but throws an uncaught error that i cannot fix (im stupid)
	async userLogin({ dispatch }, payload) {
		try {
			const response = await projectAuth.signInWithEmailAndPassword(payload.email, payload.password)
			dispatch("fetchCurrentUser", response.user)
		} catch (err) {
			console.log("Throwing error...")
			throw err
		}
	},

	async logout({ commit }) {
		await projectAuth.signOut()
		commit("setUserProfile", {})
		commit("setActiveClient", null)
		commit("setClientList", [])
		commit("setLoggedIn", false)
		commit("clearFileList")
		commit("clearFilesSinceLastVisit")
		router.push("/login")
	},

	async checkUser(_, payload) {
		try {
			let response = await firestore
				.collection("users")
				.doc(payload.email)
				.get()
			return response.exists
		} catch (err) {
			console.log(err)
		}
	},

	fetchCurrentUser({ commit, dispatch }, user) {
		firestore
			.collection("users")
			.doc(user.email)
			.get()
			.then((doc) => {
				if (doc.exists) {
					// set current user
					commit("setUserProfile", doc.data())
					commit("setLoggedIn", true)
					let activeClient
					if (doc.data().type == "stb") {
						activeClient = doc.data().activeClient
					} else {
						activeClient = doc.data().linkedClient
					}
					dispatch("fetchClient", activeClient).then((doc) => {
						dispatch("getTimelineForActiveClient", doc)
						commit("setActiveClient", doc)
					})

					if (router.currentRoute.path === "/") {
						// Already in Dashboard...
					} else if (router.currentRoute.path === "/login") {
						router.push("/")
					} else {
						//router.push('/')
					}
				} else {
					console.log("Gibt keinen User")
				}
			})
			.catch(() => {
				// do something with the error
			})
	},

	signup({ dispatch }, form) {
		let ref = firestore.collection("users").doc(form.email)
		ref.get().then((doc) => {
			if (doc.exists) {
				// user exists
			} else {
				projectAuth
					.createUserWithEmailAndPassword(form.email, form.password)
					.then((user) => {
						ref.set({
							// firstName: form.name.firstName,
							// lastName: form.name.lastName,
							name: form.name,
							email: form.email,
							userID: user.user.uid,
							invitedBy: form.invitedBy,
							type: form.type,
							linkedClient: form.linkedClient,
						})
						dispatch("fetchCurrentUser", user.user)
					})
					.catch((err) => {
						console.log(err)
					})
			}
		})
	},
}

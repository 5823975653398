/* eslint-disable no-unused-vars */
import taxwizardActions from "./actions.js"
import taxwizardMutations from "./mutations.js"
import taxwizardGetters from "./getters.js"

export default {
	state() {
		return {
			fileList: null,
			currentTwData: null,
		}
	},
	mutations: taxwizardMutations,
	actions: taxwizardActions,
	getters: taxwizardGetters,
}

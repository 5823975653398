import Vue from "vue"

import Vuetify, {
	VRow,
	VCol,
	VTextField,
	VTooltip,
	VCheckbox,
	VSelect,
	VBtn,
	VIcon,
	VDatePicker,
	VMenu,
	VTextarea,
} from "vuetify/lib/"
import { Ripple, Intersect, Touch, Resize } from "vuetify/lib/directives"

Vue.use(Vuetify, {
	components: {
		VRow,
		VTooltip,
		VCol,
		VTextField,
		VCheckbox,
		VSelect,

		VBtn,

		VIcon,
		VDatePicker,
		VMenu,
		VTextarea,
	},
	directives: { Ripple, Intersect, Touch, Resize },
})

export default new Vuetify({
	theme: {
		themes: {
			options: { customProperties: true },
			light: {
				primary: {
					base: "#344955",
					darken1: "232F34",
					lighten1: "4A6572",
				},
				secondary: "#fa8100",
			},
		},
		options: { customProperties: true },
	},
})

/* eslint-disable no-unused-vars */
export default {
	updateFileInState(state, payload) {
		// console.log("UpdateFileinState aufgerufen mit payload: ", payload)
		if (state.fileList) {
			let match
			// console.log("FileList in State: ", state.fileList)
			state.fileList.filter((updFile, index) => {
				if (updFile.filename == payload.filename) {
					// updFile.filename_original = payload.filename_original
					// updFile.relevantYears = payload.relevantYears
					// updFile.categories = payload.categories
					// updFile.lastUpdatedBy = payload.lastUpdatedBy
					// updFile.lastUpdated = payload.lastUpdated
					// updFile.comment = payload.comment
					// console.log("Match gefunden bei: ", index)
					match = index
				}
			})
			// console.log(match)
			state.fileList.splice(match, 1, payload)
			// match = null
		}
		return true
	},

	addFileInState(state, payload) {
		state.fileList.push(payload)
	},

	clearFileList(state) {
		state.fileList = null
	},

	fetchFilesSinceLastVisit(state, payload) {
		state.filesLastVisit.push(payload)
	},

	clearFilesSinceLastVisit(state) {
		state.filesLastVisit = []
	},
}

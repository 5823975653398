<template>
	<v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xs" max-width="70%" scrollable persistent>
		<v-card v-if="client" flat max-height="90%" :loading="loading">
			<v-card-title>
				Steuerwizard - {{ client.company }}
				<v-spacer></v-spacer>
				<v-icon @click="closeDialog">mdi-close</v-icon>
			</v-card-title>
			<v-card-subtitle>{{ wizard.name }} {{ wizard.year }}</v-card-subtitle>
			<v-divider class="secondary my-2"></v-divider>
			<v-card-subtitle>
				<v-row justify="center">
					Status Mandant
					<v-icon :color="getStatusColor(wizard.status_client.id).color">mdi-circle-medium</v-icon>
					<span :class="getStatusColor(wizard.status_client.id).textcolor" class="mr-10">
						{{ wizard.status_client.text }}
					</span>
					Status Steuerberater
					<v-icon :color="getStatusColor(wizard.status_stb.id).color">mdi-circle-medium</v-icon>
					<span :class="getStatusColor(wizard.status_stb.id).textcolor" class="mr-10">
						{{ wizard.status_stb.text }}
					</span>
				</v-row>
			</v-card-subtitle>
			<v-card-text>
				<v-card outlined>
					<v-card-text>
						<v-row dense>
							<v-col cols="4" class="ml-auto text-center">
								<v-btn small outlined color="primary">
									<v-icon left small>mdi-reload-alert</v-icon>
									Mandanten erinnern
								</v-btn>
							</v-col>

							<v-col cols="4" class="ml-auto text-center">
								<v-btn small outlined color="secondary">
									<v-icon left small>mdi-progress-question</v-icon>
									Status ändern
								</v-btn>
							</v-col>
							<v-col cols="4" class="ml-auto text-center">
								<v-btn small outlined color="primary">
									<v-icon left small>mdi-cloud-download</v-icon>
									Daten exportieren
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<div class="my-6">
					<div v-if="activeTaxwizard">
						<!----------------------------- DATEN AUS DEM STEUERWIZARD --------------------------------->
						<v-card>
							<v-card-title class="subtitle-1">
								Allgemeine Informationen
							</v-card-title>
							<v-divider class="secondary"></v-divider>
							<v-card-text>
								<v-row>
									<v-col cols="6">
										Firmenname
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.firmenname }}
										</span>
									</v-col>
									<v-col cols="6">
										Rechtsform
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.rechtsform.name }} ({{ activeTaxwizard.data.rechtsform.abbr }})
										</span>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="6">
										Vorname Unternehmer
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.unternehmer.vorname }}
										</span>
									</v-col>
									<v-col cols="6">
										Nachname Unternehmer
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.unternehmer.nachname }}
										</span>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="6">
										Straße + HausNr.
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.adresse.str }}
										</span>
									</v-col>
									<v-col cols="3">
										PLZ
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.adresse.plz }}
										</span>
									</v-col>
									<v-col cols="3">
										Ort
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.adresse.ort }}
										</span>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="6">
										Adresszusatz
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.adresse.zusatz }}
										</span>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
						<!----------------------------- ANSCHRIFT --------------------------------->
						<v-card class="mt-2">
							<v-card-title class="subtitle-1">
								Angaben zum Finanzamt
							</v-card-title>
							<v-divider class="secondary"></v-divider>
							<v-card-text>
								<v-row>
									<v-col cols="12">
										In welchem Bundesland sitzt das Unternehmen und welches ist das zuständige Finanzamt?
									</v-col>

									<v-col cols="6">
										Bundesland
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.bundesland }}
										</span>
									</v-col>
									<v-col cols="6">
										Finanzamt
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.finanzamt }}
										</span>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="4">
										Steuernummer
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.steuernummer }}
										</span>
									</v-col>
									<v-col cols="4">
										Wirtschafts-Identifikationsnummer
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.wirtschnummer }}
										</span>
									</v-col>
									<v-col cols="4">
										Umsatzsteuer-Identifikationsnummer
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.ustid }}
										</span>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
						<v-card class="mt-2">
							<v-card-title class="subtitle-1">
								Gewinnermittlung
							</v-card-title>
							<v-divider class="secondary"></v-divider>
							<v-card-text>
								<v-row>
									<v-col cols="12">
										Stehen die Betriebseinnahmen und Betriebsausgaben zur Verfügung?
									</v-col>
								</v-row>
								<v-row v-if="activeTaxwizard.data.gewinnermittlung.yesno">
									<v-col cols="12">
										<span class="font-weight-bold">
											Ja
										</span>
									</v-col>
									<v-col cols="4">
										Summe der Betriebseinnahmen
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.gewinnermittlung.betreinnahmen }}
										</span>
									</v-col>
									<v-col cols="4">
										Summe der Betriebsausgaben
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.gewinnermittlung.betrausgaben }}
										</span>
									</v-col>
									<v-col cols="4">
										Gewinn / Verlust
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.gewinnermittlung.gewinnverlust }}
										</span>
									</v-col>
								</v-row>
								<v-row v-else>
									<v-col>
										<span class="font-weight-bold">
											Nein
										</span>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
						<v-card class="mt-2">
							<v-card-title class="subtitle-1">
								Angaben zur Gewerbesteuer
							</v-card-title>
							<v-divider class="secondary"></v-divider>
							<v-card-text>
								<v-row>
									<v-col cols="12">
										Wurde die Rechtsform in diesem Jahr gewechselt?
									</v-col>
								</v-row>
								<v-row v-if="activeTaxwizard.data.rechtsformwechsel">
									<v-col cols="6">
										Steuernummer vor dem Wechsel
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.vor_rechtsformwechsel.steuernummer }}
										</span>
									</v-col>
									<v-col cols="6">
										Datum des Rechtsformwechsels
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.vor_rechtsformwechsel.date }}
										</span>
									</v-col>
								</v-row>
								<v-row v-else>
									<v-col>
										<span class="font-weight-bold">
											Nein
										</span>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="6">
										Bestanden Betriebsstätten in mehreren gemeinden?
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.btrst_gemeinden ? "Ja" : "Nein" }}
										</span>
									</v-col>
									<v-col cols="6">
										Erstreckten sich Betriebsstätten über mehrere Gemeinde?
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.btrst_gemeinden2 ? "Ja" : "Nein" }}
										</span>
									</v-col>
									<v-col cols="6">
										Wurde eine Betriebsstätte verlegt?
										<br />
										<span class="font-weight-bold">
											{{ activeTaxwizard.data.btrst_verlegt ? "Ja" : "Nein" }}
										</span>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
						<v-card class="mt-2">
							<v-card-title class="subtitle-1">
								Weitere Angaben zur Gewerbesteuer
							</v-card-title>
							<v-divider class="secondary"></v-divider>
							<v-card-text></v-card-text>
						</v-card>
					</div>
					<div v-else>
						Bisher keine Daten für die
						<strong>{{ wizard.name }} {{ wizard.year }}</strong>
						erfasst.
					</div>
				</div>
			</v-card-text>
			<v-divider class="secondary my-2"></v-divider>
			<v-card-actions>
				<v-row justify="center">
					<v-btn small color="secondary" @click="closeDialog">Schließen</v-btn>
				</v-row>
				<!-- <v-btn @click="test">Test</v-btn> -->
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	methods: {
		toggleDialog() {
			this.dialog = !this.dialog
		},

		closeDialog() {
			this.dialog = false
			;(this.activeTaxwizard = null), (this.client = null), (this.wizard = null)
		},

		passItem(client, wizard) {
			this.client = client
			this.wizard = wizard
			if (wizard.name == "Gewerbesteuer") {
				this.fetchActiveTaxwizard(wizard)
				this.showData = true
			}
		},
		getStatusColor(status) {
			// eslint-disable-next-line no-unused-vars
			let result
			switch (status) {
				case 1:
					return (result = {
						color: "primary",
						textcolor: "primary--text",
					})
				case 2:
					return (result = {
						color: "secondary",
						textcolor: "secondary--text",
					})
				case 3:
					return (result = {
						color: "success",
						textcolor: "success--text",
					})
				default:
					return (result = {
						color: "grey",
						textcolor: "grey--text",
					})
			}
		},

		checkData() {
			if ("data" in this.client) {
				return true
			} else return false
		},

		test() {
			console.log(this.activeTaxwizard)
		},

		async fetchActiveTaxwizard(wizard) {
			if (wizard.taxwizard_link) {
				this.loading = true
				this.activeTaxwizard = await this.$store.dispatch("fetchCurrentTwData", wizard.taxwizard_link)
				this.loading = false
			}
		},
	},
	data() {
		return {
			dialog: null,
			client: null,
			wizard: null,
			activeTaxwizard: null,
			loading: false,
			showData: false,
		}
	},
}
</script>

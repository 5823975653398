/* eslint-disable no-unused-vars */
import timelineActions from "./actions.js"
import timelineMutations from "./mutations.js"
import timelineGetters from "./getters.js"

export default {
	state() {
		return {
			timelineList: [],
		}
	},
	mutations: timelineMutations,
	actions: timelineActions,
	getters: timelineGetters,
}

/* eslint-disable no-unused-vars */
import authActions from "./actions.js"
import authMutations from "./mutations.js"
import authGetters from "./getters.js"

export default {
  state() {
    return {
      currentUser: {},
      isLoggedIn: false,
    }
  },
  mutations: authMutations,
  actions: authActions,
  getters: authGetters,
}

<template>
	<div>
		<v-row justify="center">
			<v-col>
				<Dashboard />
			</v-col>
			<!-- <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="3" class="pl-0">
				<ClientInfoBar />
			</v-col> -->
		</v-row>
	</div>
</template>

<script>
import Dashboard from "@/components/Dashboard"

export default {
	methods: {},
	data() {
		return {
			infobar: false,
		}
	},
	watch: {},
	name: "Home",
	components: {
		Dashboard,
	},
}
</script>

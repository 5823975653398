/* eslint-disable no-unused-vars */
import { firestore } from "@/firebase"

export default {
	async addCustomFields(context, payload) {
		let twClient = await firestore.collection("taxwizard_custom").doc()
		twClient.set(payload)
	},

	async addTaxWizards(context, payload) {
		let twRef
		let entry

		for (var i = 0; i < payload.categories.length; i++) {
			entry = {}
			twRef = null
			twRef = firestore.collection("taxwizard").doc(payload.categories[i].taxwizard_link)
			entry = {
				owner: payload.owner,
				client: payload.client,
				categorie: payload.categories[i].name,
				abbr: payload.categories[i].abbr,
				year: payload.categories[i].year,
			}
			await twRef.set(entry)
		}
	},

	async saveData(context, payload) {
		let twRef = firestore.collection("taxwizard").doc(payload.tlink)
		payload.lastUpdate = new Date()
		try {
			await twRef.update({
				data: payload,
			})
		} catch (err) {
			console.log("Error in store: ", err)
		}
	},

	async fetchCurrentTwData(context, payload) {
		let twRef = firestore.collection("taxwizard").doc(payload)
		try {
			const doc = await twRef.get()

			context.commit("setCurrentTwData", doc.data())
			return doc.data()
		} catch (err) {
			console.log("Error in store: ", err)
		}
	},
}

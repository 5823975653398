<template>
	<v-dialog
		v-model="dialog"
		:fullscreen="$vuetify.breakpoint.xs"
		max-width="50%"
		transition="dialog-bottom-transition"
		scrollable
		persistent
	>
		<v-card flat min-height="500px" max-height="900px">
			<v-card-title>
				Datei kommentieren
				<v-spacer></v-spacer>
				<v-btn icon small @click="closeDialog" :loading="loading">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-subtitle v-if="file">{{ file.filename_original }}</v-card-subtitle>
			<v-divider class="mb-2"></v-divider>
			<v-card-text id="commentCard">
				<div v-if="file">
					<div class="mb-6" v-for="(item, index) in file.comments" :key="index">
						<div :class="getNameAlignment(item)">
							{{ item.author.firstName }} {{ item.author.lastName }} - {{ formatTime(item.date) }}
						</div>
						<div :class="item.author.type == 'stb' ? 'd-flex justify-end' : ''">
							<v-card outlined color="grey lighten-4" max-width="60%" min-width="60%">
								<v-card-text>
									{{ item.comment }}
								</v-card-text>
							</v-card>
						</div>
					</div>
				</div>
			</v-card-text>
			<v-divider class="mb-2"> </v-divider>

			<v-card-actions>
				<v-row>
					<div class="caption pl-4">Kommentar hinzufügen</div>
					<v-col cols="12" class="mb-0">
						<v-textarea
							dense
							outlined
							rows="3"
							hide-details
							append-icon="mdi-comment"
							auto-grow
							v-model="comment.comment"
							@keyup.shift.enter="addComment"
						></v-textarea>
						<v-col class="d-flex justify-center"
							><v-btn @click="closeDialog" :loading="loading">Schließen</v-btn> <v-spacer></v-spacer
							><v-btn class="secondary" @click="addComment" :loading="loading" :disabled="!comment.comment"
								>Abschicken</v-btn
							></v-col
						>
					</v-col>
				</v-row>
			</v-card-actions>
			<!-- <v-btn @click="test"></v-btn> -->
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
	methods: {
		toggleDialog() {
			this.dialog = !this.dialog
		},

		async passItem(item) {
			this.file = item
			// this.comments = this.file.comments
			// Has to wait for DOM to load
			await this.$nextTick()
			this.elem = document.getElementById("commentCard")
			this.elem.scrollTop = this.elem.scrollHeight
		},

		async addComment() {
			if (this.comment.comment) {
				this.comment.author = this.getCurrentUser.name
				this.comment.author.type = this.getCurrentUser.type
				this.comment.date = new Date()
				this.file.comments.push(this.comment)

				this.loading = true
				await this.$store.dispatch("newComment_new", this.file)
				this.loading = false

				this.elem.scrollTop = this.elem.scrollHeight

				this.timeline = {
					entry: {
						type: "file-commented",
						date: new Date(),
						addedBy: this.getCurrentUser.name,
						filename: this.file.filename_original,
						comment: this.comment.comment,
					},
					companySlug: this.getActiveClient.companySlug,
				}

				this.comment = {}
				await this.$store.dispatch("addTimelineEntry", this.timeline)
				this.timeline = {}
			}
		},

		closeDialog() {
			this.comment = {}
			this.dialog = !this.dialog
		},

		getNameAlignment(item) {
			if (item.author.type == "stb") {
				return "caption font-weight-light d-flex justify-end primary--text"
			} else {
				return "caption font-weight-light d-flex justify-start"
			}
		},

		formatTime(timestamp) {
			let date
			if (!(timestamp instanceof Date)) {
				date = timestamp.toDate()
			} else date = timestamp

			let newDate = date.toLocaleDateString("de-DE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
			})
			return newDate
		},

		test() {
			console.log(this.elem.scrollHeight)
		},
	},
	data() {
		return {
			dialog: null,
			comments: [],
			file: null,
			loading: false,
			payload: {},
			comment: {},
			timeline: {},
			elem: null,
		}
	},

	computed: {
		...mapGetters(["getCurrentUser", "getActiveClient"]),
	},
}
</script>

/* eslint-disable no-unused-vars */
import chatActions from "./actions.js"
import chatMutations from "./mutations.js"
import chatGetters from "./getters.js"

export default {
	state() {
		return {
			chats: {},
		}
	},
	mutations: chatMutations,
	actions: chatActions,
	getters: chatGetters,
}

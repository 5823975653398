<template>
	<div>
		<v-card ref="form" elevation="10" class="secondary--text">
			<v-card-title class="primary">LOGIN</v-card-title>
			<v-card-text class="secondary--text">
				<div class="mb-8"></div>
				<v-text-field
					ref="email"
					v-model="email"
					label="E-Mail Adresse"
					dense
					required
					outlined
					prepend-inner-icon="mdi-account"
					:rules="emailRules"
					color="secondary"
					class="secondary--text"
					@keyup.enter="login"
				></v-text-field>
				<v-text-field
					ref="password"
					v-model="password"
					label="Passwort"
					type="password"
					dense
					required
					outlined
					prepend-inner-icon="mdi-lock"
					:rules="[() => !!password || 'Bitte Passwort eingeben']"
					@keyup.enter="login"
				></v-text-field>
				<v-alert type="error" v-if="error">{{ error }}</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-row justify="space-around">
					<v-btn @click="login" class="secondary mb-5" :loading="loading">
						Login
					</v-btn>
				</v-row>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
/* eslint-disable no-unused-vars */
export default {
	watch: {},
	methods: {
		async login() {
			this.loading = true
			this.formHasErrors = false
			this.error = null
			Object.keys(this.form).forEach((f) => {
				if (!this.form[f]) this.formHasErrors = true
				this.$refs[f].validate(true)
			})
			if (!this.formHasErrors) {
				try {
					await this.$store.dispatch("signIn", {
						email: this.email,
						password: this.password,
					})
				} catch (err) {
					this.error = "E-Mail oder Passwort sind nicht korrekt"
					this.loading = false
				}
			}
		},
	},
	data() {
		return {
			email: null,
			password: null,
			loading: false,
			formHasErrors: false,
			error: null,
			response: null,
			emailRules: [
				(v) => !!v || "Bitte E-Mail eingeben",
				// v => /.+@.+\..+/.test(v) || 'Bitte gültige E-Mail Adresse eingeben',
			],
		}
	},
	computed: {
		form() {
			return {
				email: this.email,
				password: this.password,
			}
		},
	},
}
</script>

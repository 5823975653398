<template>
	<div>
		<v-card>
			<v-card-title>Steuer Wizard</v-card-title>
			<v-card-text v-if="getActiveClient">
				<p>
					Willkommen beim Steuer Wizard für
					<strong>{{ getActiveClient.company }} {{ getActiveClient.rechtsform.abbr }}.</strong>
				</p>
				Die folgenden Wizards sind bereits im System hinterlegt:
				<v-data-table :items="getActiveClient.categories" :headers="headers" hide-default-footer>
					<template v-slot:[`item.progress`]="{ item }">
						<a @click.prevent="editing(item)" class="caption secondary--text">
							Bearbeitung fortsetzen
						</a>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>

		<!-- <v-btn @click="test">test</v-btn> -->
	</div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
	methods: {
		test() {
			console.log(this.getActiveClient)
		},

		editing(item) {
			this.$router.push({
				name: "TaxWizard_progress",
				params: {
					companyId: this.getActiveClient.companySlug,
					year: item.year,
					type: item.name,
					link: item.taxwizard_link,
				},
			})
		},
	},
	data() {
		return {
			headers: [
				{
					text: "Steuerart",
					value: "name",
					class: "secondary--text",
				},
				{
					text: "Steuerjahr",
					value: "year",
					class: "secondary--text",
				},
				{
					text: "Status",
					value: "status",
					class: "secondary--text",
				},
				{
					text: "Bearbeitung",
					value: "progress",
					class: "secondary--text",
				},
			],
		}
	},

	computed: {
		...mapGetters(["isTypeStb", "getActiveClient", "getCurrentUser"]),
	},
}
</script>

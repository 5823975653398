/* eslint-disable no-unused-vars */
import { storage } from "@/firebase"
import { firestore } from "@/firebase"
import firebase from "firebase"
import { increment, arrayUnion } from "@/firebase"

export default {
	async uploadAllFiles({ dispatch, getters, commit }, payload) {
		let uploadedFile
		let filesToUpload = []
		let storageRef
		let refwait
		let dbFiles
		payload.forEach((file) => {
			uploadedFile = {
				filename: file.filename,
				filename_original: file.filename_original,
				folder: file.currentClient,
				uploadedBy: file.uploadedBy,
				uploadedWhen: file.uploadedWhen,
				relevantYears: file.relevantYears || null,
				categories: file.categories || "Nicht definiert",
				comment: file.comment || null,
				comments: [],
				filesize: file.filesize,
				file: file.file,
				owner: file.owner,
				// downloadURL: null,
				// counterComment: 0,
			}
			filesToUpload.push(uploadedFile)
		})

		for (var i = 0; i < filesToUpload.length; i++) {
			storageRef = storage.ref("files/" + filesToUpload[i].folder + "/" + filesToUpload[i].filename)
			refwait = await storageRef.put(filesToUpload[i].file)
			delete filesToUpload[i].file
			// var download = await refwait.ref.getDownloadURL()
			// filesToUpload[i].downloadURL = download
			dbFiles = await firestore
				.collection("files")
				.doc(filesToUpload[i].filename)
				.set(filesToUpload[i])
			commit("addFileInState", filesToUpload[i])
		}
	},

	// OLD ---New Comment mit SubCollection
	async newComment({ commit }, payload) {
		let commentRef
		commentRef = await firestore.collection("files").doc(payload.file.filename)
		await commentRef
			.collection("comments")
			.doc()
			.set(payload.comment)
		await commentRef.update("counterComment", increment)
		commit("updateFileInState", payload.file)
	},

	// NEW ---New Comment mit Array (wegen der Anzahl der Reads)
	async newComment_new({ commit }, payload) {
		let commentRef
		commentRef = await firestore.collection("files").doc(payload.filename)
		await commentRef.update({
			comments: arrayUnion(...payload.comments),
		})
		commit("updateFileInState", payload)
	},

	async setFileInDB({ dispatch }, payload) {
		let dbFiles = await firestore.collection("files").doc(payload.filename.toString())
		try {
			// let check = await dbFiles.get()
			// if (check.exists) {
			//   console.log(check)
			//   throw new Error("File bereits vorhanden")
			// } else {
			dbFiles.set(payload)
			// }
		} catch (e) {
			console.log(e)
		}
	},

	async fetchAllFiles({ state, dispatch }, payload) {
		try {
			const query = await firestore
				.collection("files")
				.where("folder", "==", payload.currentClient)
				.get()
			state.fileList = []
			query.forEach((doc) => {
				state.fileList.push(doc.data())
			})
		} catch (e) {
			console.log(e)
		}
	},

	async updateFile({ commit }, payload) {
		let coll = firestore.collection("files").doc(payload.filename)
		try {
			await coll.update(payload)
		} catch (err) {
			console.log("Error in store: ", err)
		}
		// console.log("In Action --> springe zur Mutation: ", payload)
		commit("updateFileInState", payload)
	},

	async getDownloadURL(context, payload) {
		const fileRef = storage.ref("files/" + payload.folder + "/" + payload.filename)
		fileRef.getDownloadURL().then((url) => {
			const xhr = new XMLHttpRequest()
			xhr.responseType = "blob"
			xhr.onload = function() {
				const blob = xhr.response
				const link = document.createElement("a")
				link.href = URL.createObjectURL(blob)
				link.download = payload.filename_original

				link.click()
				URL.revokeObjectURL(link.href)
			}

			xhr.open("GET", url)
			xhr.send()
		})
	},

	async fetchFilesSinceLastVisit({ commit }, payload) {
		let lastVisit = new Date(payload.lastVisit)
		lastVisit = firebase.firestore.Timestamp.fromDate(lastVisit)

		try {
			const query = await firestore
				.collection("files")
				.where("owner", "==", payload.owner)
				.where("uploadedWhen", ">", lastVisit)
				.get()

			query.forEach((doc) => {
				commit("fetchFilesSinceLastVisit", doc.data())
			})
		} catch (e) {
			console.log(e)
		}
	},
}

/* eslint-disable no-unused-vars */
export default {
	updateTimeline(state, payload) {
		state.timelineList.push(payload)
	},

	setTimelineInState(state, payload) {
		if (payload) state.timelineList = payload.entries
	},

	clearTimeline(state) {
		state.timelineList = []
	},
}

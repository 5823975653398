/* eslint-disable no-unused-vars */
import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import Login from "../views/Login.vue"
import Clients from "../views/Clients.vue"
import ClientDetails from "../views/ClientDetails.vue"
import ClientSignup from "../views/ClientSignup.vue"
import TaxWizard from "../views/TaxWizard.vue"
import TaxWizard_ClientEditor from "../components/taxwizard/ClientEditor.vue"
import Files from "../views/Files.vue"
import Chat from "../views/Chat.vue"
import ClientOverview from "../components/Client/ClientOverview"
import Steuerwizard from "../components/Client/ClientSteuerwizard"
import Timeline from "../components/Timeline"
import { projectAuth } from "../firebase"

Vue.use(VueRouter)

const requireAuth = (to, from, next) => {
	let user = projectAuth.currentUser
	if (!user) {
		next("/login")
	} else {
		next()
	}
}

const routes = [
	{
		// TO-DO: REDICRECT FROM LOGIN PAGE TO THIS AFTER LOGGING IN
		path: "/",
		name: "Home",
		component: Home,
		beforeEnter: requireAuth,
		meta: {
			title: "Sumer.ai - Home",
		},
	},
	{
		path: "/files",
		name: "Files",
		component: Files,
		beforeEnter: requireAuth,
		meta: {
			title: "Sumer.ai - Belegmanager",
		},
	},
	{
		path: "/welcome/:companyId/:Id",
		name: "Welcome",
		component: ClientSignup,
		meta: {
			title: "Willkommen bei Sumer.ai",
		},
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: {
			title: "Sumer.ai - Login",
		},
	},
	{
		path: "/clients",
		name: "Clients",
		component: Clients,
		beforeEnter: requireAuth,
		meta: {
			title: "Sumer.ai - Mandantenübersicht",
		},
	},
	{
		path: "/taxwizard",
		name: "TaxWizard",
		component: TaxWizard,
		beforeEnter: requireAuth,
		meta: {
			title: "Sumer.ai - Steuerwizard",
		},
	},
	{
		path: "/taxwizard/:companyId/:year/:type/:link",
		name: "TaxWizard_progress",
		component: TaxWizard_ClientEditor,
		beforeEnter: requireAuth,
		meta: {
			title: "Sumer.ai - Steuerwizard",
		},
	},
	{
		path: "/chat",
		name: "Chat",
		component: Chat,
		beforeEnter: requireAuth,
		meta: {
			title: "Sumer.ai - Chat",
		},
	},

	{
		path: "/clientdetails/:client_slug",
		name: "ClientDetails",
		component: ClientDetails,
		beforeEnter: requireAuth,
		meta: {
			title: "Sumer.ai - Mandantendetails",
		},
		children: [
			{
				path: "/",
				component: ClientOverview,
				meta: {
					title: "Sumer.ai - Mandantendetails",
				},
			},
			{
				path: "steuerwizard",
				component: Steuerwizard,
				meta: {
					title: "Sumer.ai - Mandantendetails",
				},
			},
			{
				path: "files",
				component: Files,
				meta: {
					title: "Sumer.ai - Mandantendetails",
				},
			},
			{
				path: "timeline",
				component: Timeline,
				meta: {
					title: "Sumer.ai - Mandantendetails",
				},
			},
		],
	},
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
})

export default router

<template>
	<div>
		<v-sheet>
			<!-- <v-btn @click="test">
				Test
			</v-btn>
			<v-btn @click="test2">
				Test2
			</v-btn> -->
			<v-card min-height="500px" flat>
				<v-card-title>Willkommen zurück</v-card-title>
				<v-card-text v-if="getLastVisit"
					>Seit Deinem letzten Besuch am {{ formatLastVisit(getLastVisit) }} gibt es folgende Updates</v-card-text
				>
				<v-card-text>
					<v-row class="text-center justify-center">
						<v-col cols="4">
							<v-icon x-large color="secondary">mdi-file-check</v-icon>
							<p class="my-2">
								Es wurden
								<strong>{{ getFilesSinceLastVisit.length > 0 ? getFilesSinceLastVisit.length : "0" }}</strong>
								Dateien hochgeladen
							</p>
							<p
								v-if="getFilesSinceLastVisit.length > 0"
								class="caption secondary--text"
								@click="showFiles = !showFiles"
								style="cursor: pointer"
							>
								{{ showFiles ? "Weniger" : "Zeige Files" }}
							</p>
							<v-expand-transition>
								<v-data-table
									:items="getFilesSinceLastVisit"
									:headers="headers"
									hide-default-footer
									no-data-text="Keine Veränderungen seit letztem Besuch"
									dense
									v-if="showFiles"
								>
									<template v-slot:[`item.uploadedWhen`]="{ item }">
										{{ formatTime(item.uploadedWhen) }}
									</template></v-data-table
								>
							</v-expand-transition></v-col
						>
						<v-col cols="4">
							<v-icon x-large color="secondary">mdi-chat-plus</v-icon>
							<p class="my-2">Es wurden <strong>54</strong> Nachrichten erfasst</p>
							<p
								v-if="getFilesSinceLastVisit.length > 0"
								class="caption secondary--text"
								@click="showComments = !showComments"
								style="cursor: pointer"
							>
								{{ showComments ? "Weniger" : "Zeige Nachrichten" }}
							</p>
							<v-expand-transition> </v-expand-transition
						></v-col>
						<v-col cols="4">
							<v-icon x-large color="secondary">mdi-wizard-hat</v-icon>
							<p class="my-2">Es wurden <strong>2</strong> Steuerwizards bearbeitet</p>
							<p
								v-if="getFilesSinceLastVisit.length > 0"
								class="caption secondary--text"
								@click="showWizard = !showWizard"
								style="cursor: pointer"
							>
								{{ showWizard ? "Weniger" : "Zeige Kommentare" }}
							</p>
							<v-expand-transition> </v-expand-transition></v-col
					></v-row>
				</v-card-text>
			</v-card>
			<v-row>
				<v-col cols="6"> </v-col>
			</v-row>
			<!-- <TimeLine /> -->
		</v-sheet>
	</div>
</template>

<script>
import TimeLine from "@/components/Timeline"
import ClientList from "@/components/ClientList"
import AddClient from "@/components/AddClient"
import { mapGetters } from "vuex"
// eslint-disable-next-line no-unused-vars
import firebase from "firebase"
export default {
	watch: {
		getCurrentUser: function() {
			let lastVisit = new Date(this.getLastVisit)
			let payload = {
				lastVisit: lastVisit,
				owner: this.getCurrentUser.userID,
			}
			if (this.isTypeStb) {
				this.$store.dispatch("fetchFilesSinceLastVisit", payload)
			}
		},
	},
	created() {
		if (this.getCurrentUser && this.getFilesSinceLastVisit.length == 0) {
			let lastVisit = new Date(this.getLastVisit)
			let payload = {
				lastVisit: lastVisit,
				owner: this.getCurrentUser.userID,
			}
			if (this.isTypeStb) {
				this.$store.dispatch("fetchFilesSinceLastVisit", payload)
			}
		}
	},
	methods: {
		test() {
			console.log(this.getFilesSinceLastVisit)
		},
		test2() {
			console.log(this.getLastVisit)
			let i = new Date(this.getLastVisit)
			console.log(i)
			i.toDate
		},

		formatTime(timestamp) {
			let date
			if (!(timestamp instanceof Date)) {
				date = timestamp.toDate()
			} else date = timestamp

			let newDate = date.toLocaleDateString("de-DE", {
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
			})
			return newDate
		},
		formatLastVisit(timestamp) {
			let date = new Date(timestamp)

			let newDate = date.toLocaleDateString("de-DE", {
				month: "2-digit",
				day: "2-digit",
				year: "2-digit",
			})
			return newDate
		},
	},
	mounted() {
		this.$cookies.set("lastVisit", new Date(), "1y")
	},

	data() {
		return {
			headers: [
				{
					text: "Mandant",
					align: "start",
					value: "folder",
				},
				{ text: "Dateiname", value: "filename_original", sortable: false },
				{ text: "Wann", value: "uploadedWhen" },
			],
			showFiles: false,
			showComments: false,
			showWizard: false,
		}
	},
	components: {
		TimeLine,
		ClientList,
		AddClient,
	},
	computed: {
		...mapGetters(["getCurrentUser", "getClientList", "getFilesSinceLastVisit", "getLastVisit", "isTypeStb"]),
	},
	name: "Dashboard",
}
</script>

<style scoped></style>

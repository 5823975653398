<template>
	<v-dialog
		v-model="dialog"
		persistent
		:fullscreen="$vuetify.breakpoint.xs"
		max-width="600"
		transition="dialog-bottom-transition"
		scrollable
	>
		<v-card max-height="90%" :loading="loading">
			<v-card-title>
				<span>Mandanten einladen</span>
				<v-spacer></v-spacer>
				<v-btn icon small @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>Ob ein Mandant wirklich richtig steht, siehst du wenn ein Licht angeht</v-card-text>
			<v-divider class="mb-2"></v-divider>
			<v-card-text>
				<v-card-subtitle>Allgemeine Informationen zum neuen Mandanten</v-card-subtitle>
				<v-form ref="form">
					<v-text-field
						outlined
						dense
						color="secondary"
						label="Name des Unternehmens"
						prepend-icon="mdi-domain"
						v-model="clientData.company"
						:rules="[() => !!clientData.company || 'Bitte Firmennamen eingeben']"
						validate-on-blur
						solo
						hint="Test Hinweis"
					></v-text-field>
					<v-select
						:items="rechtsformen"
						item-text="name"
						item-value="abbr"
						outlined
						dense
						prepend-icon="mdi-bank"
						v-model="clientData.rechtsform"
						label="Rechtsform des Unternehmens"
						return-object
						solo
						:rules="[() => !!clientData.rechtsform || 'Bitte Rechtsform festlegen']"
						validate-on-blur
					></v-select>
					<v-text-field
						outlined
						solo
						dense
						label="Name des Kontaktperson - Optional"
						prepend-icon="mdi-account-tie"
						color="secondary"
						v-model="clientData.name"
						hint="Der Name kann später ergänzt werden"
					></v-text-field>
					<v-text-field
						outlined
						dense
						solo
						color="secondary"
						label="E-Mail des Unternehmens"
						prepend-icon="mdi-email"
						v-model="clientData.email"
						:rules="[() => !!clientData.email || 'Bitte E-Mail eingeben']"
						hint="An diese E-Mail Adresse wird der Steuer Wizard zur weiteren Bearbeitung geschickt"
						validate-on-blur
					></v-text-field>
					<v-text-field
						outlined
						solo
						dense
						color="secondary"
						label="Externe Mandantennummer (z.B. DATEV) - Optional"
						prepend-icon="mdi-apple-icloud"
						v-model="clientData.externalId"
					></v-text-field>

					<v-row dense align="center">
						<v-col cols="12">
							<v-switch
								v-model="setcategories"
								solo
								inset
								color="secondary"
								label="Soll direkt ein Steuerwizard für diesen Mandanten angelegt werden?"
								hint="Es können nach Anlage des Mandanten beliebig viele steuerliche Schwerpunkte gesetzt werden"
							></v-switch>
						</v-col>
					</v-row>
					<v-expand-transition>
						<div v-if="setcategories">
							<v-row dense>
								<v-col cols="12">
									<v-combobox
										v-model="selected"
										:items="steuerarten"
										label="Bitte auswählen"
										outlined
										item-text="name"
										color="secondary"
										solo
										small-chips
										multiple
										prepend-icon="mdi-wizard-hat"
									>
										<template v-slot:selection="data">
											<v-chip
												:key="JSON.stringify(data.item)"
												v-bind="data.attrs"
												color="secondary"
												:input-value="data.selected"
												small
												:disabled="data.disabled"
												@click:close="data.parent.selectItem(data.item)"
											>
												<v-avatar
													class="primary white--text"
													left
													v-text="data.item.name.slice(0, 1).toUpperCase()"
												></v-avatar>
												{{ data.item.name }}
											</v-chip>
										</template>
									</v-combobox>
								</v-col>
								<v-col cols="12">
									Für welche Jahre soll diese Erstauswahl zutreffend sein?
								</v-col>
								<v-col cols="3">
									<v-switch label="2020" value="2020" hide-details inset color="secondary" v-model="years"></v-switch>
								</v-col>
								<v-col cols="3">
									<v-switch label="2019" value="2019" hide-details inset color="secondary" v-model="years"></v-switch>
								</v-col>
								<v-col cols="3">
									<v-switch label="2018" value="2018" hide-details inset color="secondary" v-model="years"></v-switch>
								</v-col>
								<v-col cols="3">
									<v-switch label="2017" value="2017" hide-details inset color="secondary" v-model="years"></v-switch>
								</v-col>
							</v-row>
							<!-- <v-row align="center" justify="start">
								<v-col v-for="(selection, i) in selections" :key="selection.name" class="shrink">
									<v-chip close @click:close="selected.splice(i, 1)" color="secondary">
										<v-icon left>mdi-check</v-icon>
										{{ selection.name }}
									</v-chip>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-card-subtitle>Welche Schwerpunkte werden gesetzt:</v-card-subtitle>
							<v-list dense>
								<template v-for="item in categories">
									<v-list-item v-if="!selected.includes(item)" :key="item.name" @click="selected.push(item)" dense>
										<v-list-item-title v-text="item.name" class="text-caption"></v-list-item-title>
									</v-list-item>
								</template>
							</v-list> -->
						</div>
					</v-expand-transition>
				</v-form>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions class="d-flex justify-center">
				<v-btn class="error" @click="closeDialog" :loading="loading">
					Abbrechen
				</v-btn>
				<v-btn class="success ml-10" @click="addClient">Mandant anlegen</v-btn>
				<!-- <v-btn @click="test">TEST</v-btn> -->
			</v-card-actions>
			<v-snackbar v-model="snackbar.state" timeout="5000" :color="snackbar.color">
				<v-icon>{{ snackbar.icon }}</v-icon>
				{{ snackbar.text }}
			</v-snackbar>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import slugify from "slugify"
import steuerarten from "@/assets/steuerarten.json"
import rechtsformen from "@/assets/rechtsformen.json"

export default {
	methods: {
		async addClient() {
			if (this.$refs.form.validate()) {
				if (this.clientData) {
					this.clientData.companySlug = slugify(this.clientData.company, {
						replacement: "-",
						remove: /[$*_+~.()'"!\-:@/]/g,
						lower: true,
					})
					this.clientData.owner = this.getCurrentUser.userID
					// SELECTIONS --------------
					for (var i = 0; i < this.selected.length; i++) {
						for (var ii = 0; ii < this.years.length; ii++) {
							this.selected_new.push({
								name: this.selected[i].name,
								abbr: this.selected[i].abbr,
								year: this.years[ii],
								status_client: {
									id: 1,
									text: "open",
								},
								status_stb: {
									id: 1,
									text: "open",
								},
								taxwizard_link: this.createRandomTaxWizardStrong(),
							})
						}
					}
					this.clientData.categories = this.selected_new
					try {
						const payload = {
							client: this.clientData.companySlug,
							owner: this.getCurrentUser.userID,
							categories: this.clientData.categories,
						}

						await this.$store.dispatch("addTaxWizards", payload)
					} catch (e) {
						//
					}
					// -------------------------
					this.clientData.taxwizard.linkId = this.generateLinkId()
					console.log("/", this.clientData.companySlug, "/", this.clientData.taxwizard.linkId)

					try {
						this.loading = true
						await this.$store.dispatch("createNewClient", this.clientData)
						this.clientList.push(this.clientData)
						this.$emit("addedClient")
						this.clientData = {}
						this.toggleDialog()
						this.loading = false
					} catch (err) {
						this.snackbar.text = "Fehler - Firma bereits im System vorhanden"
						this.snackbar.icon = "mdi-cancel"
						this.snackbar.color = "error"
						this.snackbar.state = true
						this.loading = false
						console.log(err)
					}
				} else {
					console.log("nichts drin..")
				}
			} else {
				// Form nicht ready
			}
		},

		toggleDialog() {
			this.dialog = !this.dialog
		},

		createRandomTaxWizardStrong(length = 25) {
			let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
			let str = ""
			for (let i = 0; i < length; i++) {
				str += chars.charAt(Math.floor(Math.random() * chars.length))
			}
			return str
		},

		closeDialog() {
			this.$refs.form.resetValidation()
			this.dialog = !this.dialog
		},

		generateLinkId() {
			var linkId =
				Math.random()
					.toString(36)
					.substring(2, 15) +
				Math.random()
					.toString(36)
					.substring(2, 15)
			return linkId
		},

		test() {
			console.log(this.createRandomTaxWizardStrong())
			// for (var i = 0; i < this.years.length; i++) {
			// 	console.log(this.years[i])
			// 	console.log(this.selections)

			// }

			// for (var i = 0; i < this.selected.length; i++) {
			// 	for (var ii = 0; ii < this.years.length; ii++) {
			// 		this.selected_new.push({
			// 			name: this.selected[i].name,
			// 			abbr: this.selected[i].abbr,
			// 			year: this.years[ii],
			// 		})
			// 	}
			// }
			// console.log(this.selected_new)
		},
	},
	data() {
		return {
			steuerarten: steuerarten,
			years: [],
			loading: false,
			selected: [],
			selected_new: [],
			setcategories: false,
			snackbar: {
				state: false,
				text: null,
				color: null,
				icon: null,
			},
			clientData: {
				name: null,
				company: null,
				email: null,
				categories: [],
				companySlug: null,
				externalId: null,
				owner: null,
				assigned: null,
				rechtsform: null,
				taxwizard: {
					linkId: null,
					link_visited: false,
					started: false,
					finished: false,
				},
			},
			rechtsformen: rechtsformen,

			dialog: null,
		}
	},
	name: "AddClient",
	computed: {
		...mapState(["userProfile", "activeClient", "clientList"]),
		...mapGetters(["getCurrentUser"]),

		allSelected() {
			return this.selected.length === this.items.length
		},

		categories() {
			return this.items
		},

		selections() {
			const selections = []

			for (const selection of this.selected) {
				selections.push(selection)
			}
			return selections
		},
	},
}
</script>

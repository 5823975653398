/* eslint-disable no-unused-vars */
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import { projectAuth } from "./firebase"
import VueCookies from "vue-cookies"

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(VueCookies)

let app

projectAuth.onAuthStateChanged((user) => {
	if (!app) {
		app = new Vue({
			router,
			store,
			vuetify,
			render: (h) => h(App),
		}).$mount("#app")

		if (user) {
			// console.log(user)
			store.dispatch("fetchCurrentUser", user)
		}
	}
})

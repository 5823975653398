<template>
	<v-app>
		<Appbar v-if="showNav" @toggleNav="toggleNav" @toggleRightNav="toggleRightNav" />
		<Navbar v-if="showNav" ref="navBar" />
		<RightNavBar ref="rightNavBar" v-if="isTypeStb" />
		<v-main app>
			<v-container fluid>
				<!-- <v-btn @click="test">Test</v-btn> -->
				<transition name="route" mode="out-in">
					<router-view></router-view>
				</transition>
			</v-container>
		</v-main>
		<v-footer padless app absolute>
			<v-card class="flex primary text-center sheetImage" tile flat>
				<v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
					<v-icon>{{ icon }}</v-icon>
				</v-btn>
				<v-card-text class="white--text pt-0"></v-card-text>

				<v-card-text class="py-0 secondary text-center black--text my-0 sheetImage">
					{{ new Date().getFullYear() }} — sumerai UG
				</v-card-text>
			</v-card>
		</v-footer>
	</v-app>
</template>

<script>
import Navbar from "@/components/Navbar"
import RightNavBar from "@/components/RightNavBar"
import Appbar from "@/components/Appbar"
import { mapGetters } from "vuex"

export default {
	watch: {
		$route: {
			// eslint-disable-next-line no-unused-vars
			handler: (to, from) => {
				document.title = to.meta.title || "Sumer.ai"
			},
			immediate: true,
		},
	},
	data() {
		return {
			icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
		}
	},
	methods: {
		toggleNav() {
			this.$refs.navBar.toggleDrawer()
		},

		toggleRightNav() {
			this.$refs.rightNavBar.toggleDrawer()
		},

		test() {
			this.$refs.rightNavBar.toggleDrawer()
		},
	},

	computed: {
		...mapGetters(["isLoggedIn", "isTypeStb"]),
		showNav() {
			// console.log(this.isLoggedIn)
			// return Object.keys(this.getCurrentUser).length > 1
			return this.isLoggedIn
		},
	},
	components: {
		Navbar,
		Appbar,
		RightNavBar,
	},
}
</script>

<style>
.sheetImage {
	background-image: url("~@/assets/background.png");
	background-repeat: repeat;
}

.route-enter-from {
	opacity: 0;
	transform: translateY(-30px);
}

.route-leave-to {
	opacity: 0;
	transform: translateY(30px);
}

.route-enter-active {
	transition: all 0.3s ease-out;
}

.route-leave-active {
	transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
	opacity: 1;
	transform: translateY(0);
}
</style>

/* eslint-disable no-unused-vars */
import { firestore } from "@/firebase"

export default {
	async updateClient({ dispatch }, payload) {
		let coll = firestore.collection("clients").doc(payload.companySlug)
		try {
			await coll.update(payload)
		} catch (err) {
			console.log("Error in store: ", err)
		}
	},

	async updateClientLink({ dispatch }, payload) {
		let coll = firestore.collection("clients").doc(payload)
		try {
			await coll.update({
				"taxwizard.link_visited": true,
			})
		} catch (err) {
			console.log("Error in store: ", err)
		}
	},

	async createNewClient({ dispatch }, payload) {
		let dbClient = firestore.collection("clients").doc(payload.companySlug)
		let check = await dbClient.get()
		if (check.exists) {
			throw new Error("Firma bereits vorhanden")
		} else {
			dbClient.set(payload)
		}
	},

	async fetchAllClients({ dispatch }, userID) {
		try {
			const query = await firestore
				.collection("clients")
				.where("owner", "==", userID)
				.get()
			this.state.clientList = []
			query.forEach((doc) => {
				this.state.clientList.push(doc.data())
			})
		} catch (e) {
			console.log(e)
		}
	},

	async fetchClient({ dispatch }, payload) {
		try {
			const query = firestore.collection("clients").doc(payload)
			const document = await query.get()
			return document.data()
		} catch (e) {
			//
		}
	},

	async updateLastAktiveClient({ getters, commit }, payload) {
		let currentUser = getters.getCurrentUser
		let coll = firestore.collection("users").doc(currentUser.email)
		try {
			await coll.update({
				activeClient: payload.companySlug,
			})
			commit("setActiveClient", payload)
		} catch (err) {
			console.log("Error in store: ", err)
		}
	},
}

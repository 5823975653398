export default {
	setActiveClient(state, val) {
		state.activeClient = val
	},
	setClientList(state, val) {
		state.clientList = val
	},

	setLastVisit(state, val) {
		state.lastVisit = val
	},
}

/* eslint-disable no-unused-vars */
import Vue from "vue"
import Vuex from "vuex"

import rootMutations from "./mutations.js"
import rootActions from "./actions.js"
import authModule from "./auth/index.js"
import filesModule from "./files/index.js"
import taxwizardModule from "./taxwizard/index.js"
import timelineModule from "./timeline/index.js"
import chatModule from "./chat/index.js"
import rootGetters from "./getters.js"

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		userProfile: {},
		activeClient: null,
		clientList: [],
		lastVisit: null,
	},
	mutations: rootMutations,
	actions: rootActions,
	getters: rootGetters,
	modules: {
		auth: authModule,
		files: filesModule,
		taxwizard: taxwizardModule,
		timeline: timelineModule,
		chat: chatModule,
	},
})

export default store

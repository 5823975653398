<template>
	<v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xs" max-width="30%">
		<v-card v-if="client" flat>
			<v-card-text class="mt-4">
				{{ client.company }} {{ client.rechtsform.abbr }}
				<v-divider></v-divider>
				<p>Status Mandant: {{ wizard.status }}</p>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	methods: {
		toggleDialog() {
			this.dialog = !this.dialog
		},
		passItem(client, wizard) {
			this.client = client
			this.wizard = wizard
			// Object.assign(this.updatedFile, item)
		},
	},
	data() {
		return {
			dialog: null,
			client: null,
			wizard: null,
		}
	},
}
</script>

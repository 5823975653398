var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-center justify-center"},[_c('h2',[_vm._v("Deine Mandanten")])]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"5","lg":"3","sm":"6"}},[_c('v-card',{attrs:{"dense":"","outlined":""}},[_c('v-expansion-panels',{attrs:{"flat":"","hover":"","title":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"subtitle-2"},[_c('div',[_c('v-icon',{attrs:{"color":"secondary","left":""}},[_vm._v("mdi-filter")]),_c('span',[_vm._v("Filter Optionen...")])],1)]),_c('v-expansion-panel-content',[_c('v-text-field',{staticClass:"body-2",attrs:{"prepend-icon":"mdi-magnify","label":"Filter Mandanten...","single-line":"","hide-details":"","dense":"","color":"secondary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.getClientList,"headers":_vm.headers,"hide-default-footer":"","loading":_vm.isLoading,"loading-text":"Lese Mandantendaten...","no-data-text":"Bisher keine Mandanten angelegt","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"align-self":"center"}},[_c('v-btn',{attrs:{"color":"secondary","elevation":"10"},on:{"click":_vm.addClient}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_vm._v(" Mandanten hinzufügen ")],1)],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatus(item.taxwizard).color,"label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(_vm._s(_vm.getStatus(item.taxwizard).icon))]),_vm._v(" "+_vm._s(_vm.getStatus(item.taxwizard).status)+" ")],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(_vm.checkActive(item))?_c('v-chip',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.activateClient(item)}}},[_c('v-icon',{attrs:{"left":"","small":"","color":"secondary"}},[_vm._v("mdi-chevron-up-circle")]),_vm._v(" Aktivieren ")],1):_vm._e(),(!_vm.checkActive(item))?_c('v-chip',{attrs:{"color":"success","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-check")]),_vm._v(" Aktiver Mandant ")],1):_vm._e()]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.company))]),(false)?_c('a',{staticClass:"caption secondary--text",on:{"click":function($event){$event.preventDefault();return _vm.clientDetails(item)}}},[_vm._v(" - Details ")]):_vm._e(),_c('p',{staticClass:"caption mb-0 primary--text"},[_vm._v(" "+_vm._s(item.rechtsform.abbr)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.action(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_vm._v(" Editieren ")]),_c('v-tooltip',{attrs:{"top":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"},on:{"click":function($event){return _vm.activateClient(item)}}},on),[_vm._v(" mdi-account-reactivate ")])]}}],null,true)},[_vm._v(" Mandant aktivieren ")])]}}],null,true)}),_c('v-divider',{staticClass:"mb-10"}),_c('AddClient',{ref:"addClientDialog",on:{"addedClient":_vm.addedClient}}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar.timeout,"color":"success"},model:{value:(_vm.snackbar.status),callback:function ($$v) {_vm.$set(_vm.snackbar, "status", $$v)},expression:"snackbar.status"}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
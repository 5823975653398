<template>
	<div>
		<DetailsDialog ref="detailsdialog" />
		<v-row>
			<v-col>
				<v-card>
					<v-card-title class="subtitle-2">
						Allgemein - Anzahl der Steuer Wizards und deren Steuerberater-Bearbeitungsstatus
					</v-card-title>
					<v-divider class="secondary"></v-divider>
					<v-card-text>
						<v-row>
							<v-col md="4" lg="3" sm="6" xs="12" class="text-center">
								Anzahl Steuer Wizards
								<br />
								<span class="font-weight-bold">
									<h1 class="my-4 secondary--text">{{ getActiveClient.categories.length }}</h1>
								</span>
							</v-col>
							<v-col md="4" lg="3" sm="6" xs="12" class="text-center">
								Offen
								<br />
								<span class="font-weight-bold">
									<h1 class="my-4 secondary--text">{{ getStatusNumber("open") }}</h1>
								</span>
							</v-col>
							<v-col md="4" lg="3" sm="6" xs="12" class="text-center">
								In Bearbeitung
								<br />
								<span class="font-weight-bold">
									<h1 class="my-4 secondary--text">{{ getStatusNumber("in progress") }}</h1>
								</span>
							</v-col>
							<v-col md="4" lg="3" sm="6" xs="12" class="text-center">
								Finalisiert
								<br />
								<span class="font-weight-bold">
									<h1 class="my-4 secondary--text">{{ getStatusNumber("finished") }}</h1>
								</span>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-card>
			<v-card-title class="subtitle-2">Alle Steuerwizards für {{ getActiveClient.company }}</v-card-title>
			<v-divider class="secondary"></v-divider>
			<v-card-text>
				<v-row>
					<v-col cols="6" class="mb-6">
						<v-text-field
							outlined
							color="secondary"
							dense
							label="Filter"
							prepend-icon="mdi-magnify"
							v-model="search"
							clearable
							hide-details
						></v-text-field>
					</v-col>
				</v-row>

				<v-data-table
					:items="getActiveClient.categories"
					hide-default-footer
					no-data-text="Bisher keine Steuer Wizards im System hinterlegt."
					no-results-text="Keine passenden Daten gefunden"
					:headers="headers"
					:search="search"
				>
					<template v-slot:[`item.name`]="{ item }">
						<span class="font-weight-bold">{{ item.name }}</span>
					</template>
					<template v-slot:[`item.status_client`]="{ item }">
						<v-icon :color="getStatusColor(item.status_client.id).color">mdi-circle-medium</v-icon>
						<span :class="getStatusColor(item.status_client.id).textcolor">
							{{ item.status_client.text }}
						</span>
					</template>
					<template v-slot:[`item.status_stb`]="{ item }">
						<v-icon :color="getStatusColor(item.status_stb.id).color">mdi-circle-medium</v-icon>
						<span :class="getStatusColor(item.status_stb.id).textcolor">
							{{ item.status_stb.text }}
						</span>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-btn icon left @click="openDetailsDialog(item)">
							<v-icon>mdi-text-box-search-outline</v-icon>
						</v-btn>
						<v-icon right class="mx-2">mdi-cloud-download</v-icon>
						<v-icon>mdi-reload-alert</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
		<!-- <v-data-iterator
			:items="getActiveClient.categories"
			hide-default-footer
			no-data-text="Bisher keine Daten im System gefunden"
		>
			<template v-slot:default="props">
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="6" v-for="prop in props.items" :key="prop.taxwizard_link">
						<v-card>
							<v-card-title class="subtitle-2">{{ prop.name }} {{ prop.year }}</v-card-title>
							<v-divider class="secondary"></v-divider>
							<v-card-text></v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</template>
		</v-data-iterator> -->
	</div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex"
import DetailsDialog from "@/components/taxwizard/StbDetails.vue"
export default {
	methods: {
		getStatusNumber(status) {
			let number = 0
			for (var i = 0; i < this.getActiveClient.categories.length; i++) {
				if (this.getActiveClient.categories[i].status == status) {
					number++
				}
			}
			return number
		},

		getStatusColor(status) {
			let result
			switch (status) {
				case 1:
					return (result = {
						color: "primary",
						textcolor: "primary--text",
					})
				case 2:
					return (result = {
						color: "secondary",
						textcolor: "secondary--text",
					})
				case 3:
					return (result = {
						color: "success",
						textcolor: "success--text",
					})
				default:
					return (result = {
						color: "grey",
						textcolor: "grey--text",
					})
			}
		},

		openDetailsDialog(item) {
			this.twLink = item.taxwizard_link
			this.$refs.detailsdialog.toggleDialog()
			this.$refs.detailsdialog.passItem(this.getActiveClient, item)
		},
	},
	data() {
		return {
			headers: [
				{
					text: "Steuerart",
					value: "name",
				},
				{
					text: "Jahr",
					value: "year",
				},
				{
					text: "Status Mandant",
					value: "status_client",
				},
				{
					text: "Status Steuerberater",
					value: "status_stb",
				},
				{
					text: "Aktionen",
					value: "actions",
				},
			],
			search: null,
			twLink: null,
		}
	},

	computed: {
		...mapGetters(["getCurrentUser", "getActiveClient"]),
	},

	components: { DetailsDialog },
}
</script>

<template>
  <v-card elevation="0" class="mx-auto">
    <v-card-subtitle>Mandanten Quick Search</v-card-subtitle>
    <v-sheet>
      <v-text-field
        flat
        hide-details
        label="Suche Mandanten..."
        dense
        v-model="search"
        clearable
        clear-icon="mdi-close"
      >
      </v-text-field>
      <v-card-text>
        <v-treeview
          open-on-click
          transition
          :items="items"
          :filter="filter"
          :search="search"
        >
          <template v-slot:append="{ item }">
            <v-btn
              v-if="!item.children"
              dense
              x-small
              color="teal"
              @click="setActiveClient(item)"
            >
              <v-icon small color="white">
                mdi-arrow-top-right-thin-circle-outline
              </v-icon>
            </v-btn>
          </template>
        </v-treeview>
      </v-card-text>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  methods: {
    setActiveClient(client) {
      this.$store.commit("setActiveClient", client)
      //console.log(this.$store.state.activeClient)
    },
  },
  data() {
    return {
      clients: [
        {
          id: 1,
          rechtsform: "UG",
          name: "Stefan Kiehne",
          company: "Kiehne Media UG",
          status: "Nicht abgeschlossen",
          telefon: "12345566",
          email: "mail@kiehne.com",
          companySlug: "kiehne-media-ug",
        },
        {
          id: 2,
          name: "Bettina Fischer",
          rechtsform: "AG",
          company: "Glühwein mit Schuss AG",
          status: "Abgeschlossen",
          telefon: "0151 23123 123 123",
          email: "bettina@fischermails.de",
        },
        {
          id: 3,
          name: "Puki",
          rechtsform: "GmbH",
          company: "Pukinauten GmbH",
          status: "Nicht abgeschlossen",
          telefon: "0171 123 1 312",
          email: "puki@kiehne.com",
        },
      ],
      search: null,
      caseSensitive: false,
    }
  },
  name: "Clientnav",
  computed: {
    items() {
      return [
        {
          name: "Liste Mandanten",
          children: this.clients,
        },
      ]
    },
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
  },
}
</script>

<template>
    <div>
        <v-card elevation="10" ref="form">
            <v-card-title class="title font-weight-regular justify-space-between">
                <span>Account erstellen</span>
                <v-avatar
                    color="secondary"
                    class="subheading white--text"
                    siz="24"
                    v-text="step"
                ></v-avatar>
            </v-card-title>
            <v-window v-model="step">
                <v-window-item :value="1">
                    <v-card-text>
                        <v-text-field
                            label="E-Mail Adresse"
                            v-model="userSignup.email"
                            required
                            outlined
                            dense
                            prepend-inner-icon="mdi-at"
                            :rules="emailRules"
                            hint="Deine E-Mail Adresse wird nicht für Werbung verwendet!"
                        >
                        </v-text-field>
                        <span class="caption grey--text text--darken-1">
                            Deine E-Mail Adresse wird Dein Account Name und zum Login verwendet werden
                        </span>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                    <v-card-text>
                        <v-text-field
                            label="Vorname"
                            v-model="userSignup.name.firstName"
                            required
                            outlined
                            dense
                            prepend-inner-icon="mdi-badge-account-horizontal"
                        >
                        </v-text-field>
                        <v-text-field
                            label="Nachname"
                            v-model="userSignup.name.lastName"
                            required
                            outlined
                            dense
                            prepend-inner-icon="mdi-badge-account-horizontal"
                        >
                        </v-text-field>
                        <span class="caption grey--text text--darken-1">
                            Deinen Namen verwenden wir um Dir kostenlose Dildos und Analplugs zuzusenden. 
                            Sobald Dein Account angelegt ist, kannst Du noch weitere Informationen, wie z.B. Firmennamen, etc. angeben
                        </span>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                    <v-card-text>
                        <v-text-field
                            label="Passwort"
                            v-model="userSignup.password"
                            type="password"
                            required
                            outlined
                            dense
                            prepend-inner-icon="mdi-lock"
                        >
                        </v-text-field>
                        <v-text-field
                            label="Passwort wiederholen"
                            v-model="password2"
                            type="password"
                            required
                            dense
                            outlined
                            prepend-inner-icon="mdi-lock-reset"
                        >
                        </v-text-field>
                        <span class="caption grey--text text--darken-1">
                            Bitte vergib ein starkes Passwort für Deinen Account. Deine Privatsphäre ist nämlich scheiss egal!
                        </span>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="4">
                    <v-card-text>
                        <v-subheader>Zusammenfassung deiner Daten</v-subheader>
                        <v-list>
                            <v-list-item>
                                Vorname: {{userSignup.name.firstName}}
                            </v-list-item>
                            <v-list-item>
                                Nachname: {{userSignup.name.lastName}}
                            </v-list-item>
                            <v-list-item>
                                E-Mail Adresse: {{userSignup.email}}
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-window-item>

            </v-window>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn
                    :disabled="step === 1"
                    text
                    @click="step--, errorMessage=null"
                >Zurück</v-btn>
                <v-spacer></v-spacer>
                <v-alert 
                    type="error"
                    outlined 
                    dense
                    text
                    class="mt-6"
                    v-if="errorMessage"
                    max-width="50%"
                >{{ errorMessage }}</v-alert>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="step < 4"
                    color="primary"
                    depressed
                    @click="wizardForward"
                >Weiter</v-btn>
                <v-btn
                    color="success"
                    depressed
                    v-if="step === 4"
                    @click="signup"
                >Account erstellen</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
  methods: {
    signup() {
        this.$store.dispatch('signup', this.userSignup)
    },

    async wizardForward() {

        switch (this.step) {
            case 1: {
                const response = await this.$store.dispatch('checkUser', {
                    email: this.userSignup.email
                })
                if(!response) {
                    if(this.validateEmail(this.userSignup.email)) {
                        this.step++
                        this.errorMessage = null
                    } else {
                        this.errorMessage = "Bitte eine gültige E-Mail Adresse eingeben"
                    }
                } else {
                    this.errorMessage = "Benutzer existiert bereits"
                }
                break
            }
            case 2: {
                if(this.userSignup.name.firstName && this.userSignup.name.lastName) {
                    this.step++
                    this.errorMessage = null
                } else {
                    this.errorMessage = "Bitte alle Felder des Namens ausfüllen"
                }
                break
            }
            case 3: {
                if(this.userSignup.password == this.password2) {
                    this.step++
                    this.errorMessage = null
                } else {
                    this.errorMessage = "Die Passwörter stimmen nicht überein"
                }
            }
        }
    },

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        return re.test(email)
    },
  },
  watch: {
  },
  props: {
  },
  data () {
    return {
        step: 1,
        errorMessage: null,
        password2: null,

        userSignup: {
            email: null,
            name: {
                firstName: null,
                lastName: null,    
            },
            password: null,
            type: 'user',
            invitedBy: null,
            linkedClient: null
        },

        emailRules: [
            v => !!v || 'Bitte E-Mail eingeben',
        ],
    }
  },
    
}
</script>
<template>
	<div>
		<!-- <v-btn @click="test"></v-btn> -->
		<v-card flat v-if="this.clientData.taxwizard.finished">
			<v-alert prominent type="warning" color="error" dense text outlined>
				<v-row align="center">
					<v-col class="grow">
						Dieser Link wurde bereits bearbeitet - eine weitere Bearbeitung ist nur für authentifizierte Benutzer
						möglich
						<p>Bitte loggen Sie sich ein: LOGIN</p>
					</v-col>
					<v-col class="shrink">
						<v-btn color="secondary" @click="$router.push('/login')">Zum Login</v-btn>
					</v-col>
				</v-row>
			</v-alert>
		</v-card>
		<v-card v-if="!this.clientData.taxwizard.finished">
			<v-app-bar color="primary" dark>
				<v-toolbar-title>
					Test
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-icon color="secondary">mdi-information-outline</v-icon>
			</v-app-bar>
			<v-stepper vertical v-model="step">
				<v-stepper-step step="1">
					Willkommen bei Sumer.ai!
				</v-stepper-step>
				<v-stepper-content step="1">
					<v-card>
						<v-card-title>
							<span class="secondary--text" v-text="clientData.company"></span>
						</v-card-title>
						<v-card-text>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta suscipit accusamus incidunt!
							Saepe maxime numquam modi officiis fugiat! Unde iste, eius quo nesciunt accusamus velit saepe sit ab.
							Laborum distinctio incidunt libero fuga vel asperiores mollitia, iure culpa dolorem ducimus autem dicta
							dolorum quos blanditiis illo alias aut eius? Officia eos deleniti vel quae non eius, voluptatem cum
							laboriosam deserunt quidem aut. Laborum, consectetur. Doloribus amet maiores optio itaque soluta
							molestias repellat ratione accusantium enim reiciendis natus illum necessitatibus totam, ea nihil,
							delectus quis eius odit voluptatum assumenda! Fuga aliquam optio, asperiores atque ipsam iusto
							laboriosam voluptatum aperiam eaque!
						</v-card-text>
						<v-card-actions>
							<v-row justify="center">
								<v-btn color="secondary" @click="linkVisited">
									Starten
								</v-btn>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
				<v-stepper-step step="2" editable>
					Konto anlegen
					<small>Dein persönliches Sumer.ai Konto</small>
				</v-stepper-step>
				<!------------------------- STEP 2 - ACCOUNT -------------------------------------->
				<v-stepper-content step="2">
					<v-card>
						<v-card-title>Benutzerkonto</v-card-title>
						<v-card-text>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum obcaecati soluta doloribus molestiae
							pariatur vel? Magni sunt atque dolor sed odit rem perferendis adipisci impedit tempore quia eveniet,
							inventore quo.
							<v-divider class="my-6"></v-divider>
							<v-form ref="form_account" autocomplete="off">
								<v-row dense>
									<v-col cols="12">
										<v-text-field
											label="E-Mail Adresse"
											color="secondary"
											v-model="userSignup.email"
											required
											outlined
											dense
											prepend-icon="mdi-at"
											:rules="emailRules"
											hint="Deine E-Mail Adresse wird nicht für Werbung verwendet!"
											validate-on-blur
										></v-text-field>
									</v-col>
									<v-col cols="6" md="6" sd="4">
										<v-text-field
											label="Vorname"
											color="secondary"
											v-model="userSignup.name.firstName"
											required
											outlined
											dense
											prepend-icon="mdi-badge-account-horizontal"
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-col cols="6" md="6" sd="4">
										<v-text-field
											label="Nachname"
											color="secondary"
											v-model="userSignup.name.lastName"
											required
											outlined
											dense
											prepend-icon="mdi-badge-account-horizontal"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="6">
										<v-text-field
											label="Passwort"
											color="secondary"
											v-model="userSignup.password"
											type="password"
											required
											outlined
											dense
											prepend-icon="mdi-lock"
											:rules="[passwordConfirmationRule]"
											validate-on-blur
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-col cols="6">
										<v-text-field
											label="Passwort wiederholen"
											color="secondary"
											type="password"
											v-model="rePassword"
											required
											dense
											outlined
											prepend-icon="mdi-lock-reset"
										></v-text-field>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-row justify="center">
								<v-btn color="secondary" @click="taxwizard_signup">
									Konto erstellen und Bearbeitung fortsetzen
								</v-btn>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
				<v-stepper-step step="3" editable>
					Allgemeine Informationen
					<small>Firmenname, Kontaktperson, etc..</small>
				</v-stepper-step>
				<!------------------------- STEP 3 - ALLGEMEIN -------------------------------------->
				<v-stepper-content step="3">
					<v-card>
						<v-card-title>Allgemeine Informationen zum Unternehmen</v-card-title>
						<v-card-subtitle>
							Die Daten wurden bereits vom Steuerberater vorgefüllt - Bitte überprüfen und ergänzen.
							<p>
								<strong>Info:</strong>
								Der Name und die Rechtsform kann nur von Ihrem Steuerberater geändert werden.
							</p>
						</v-card-subtitle>
						<v-divider></v-divider>
						<v-card-text>
							<v-form ref="form_allgemein">
								<v-row dense>
									<v-col cols="7">
										<v-tooltip top color="error" open-on-click>
											<template v-slot:activator="{ on }">
												<v-text-field
													outlined
													dense
													color="error"
													label="Name des Unternehmens"
													prepend-icon="mdi-domain"
													append-icon="mdi-close-octagon"
													v-model="updatedClientData.company"
													readonly
													v-on="on"
												></v-text-field>
											</template>
											<span>Kann nur vom Steuerberater geändert werden</span>
										</v-tooltip>
									</v-col>
									<v-col cols="5">
										<v-tooltip top color="error" open-on-click>
											<template v-slot:activator="{ on }">
												<v-text-field
													outlined
													dense
													color="error"
													label="Rechtsform"
													prepend-icon="mdi-domain"
													append-icon="mdi-close-octagon"
													v-model="updatedClientData.rechtsform.abbr"
													readonly
													v-on="on"
												></v-text-field>
											</template>
											<span>Kann nur vom Steuerberater geändert werden</span>
										</v-tooltip>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12">
										<v-text-field
											outlined
											dense
											label="Name des Kontaktperson"
											prepend-icon="mdi-account-tie"
											color="secondary"
											v-model="updatedClientData.name"
										></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field
											outlined
											dense
											label="E-Mail der Kontaktperson"
											prepend-icon="mdi-email"
											color="secondary"
											v-model="updatedClientData.email"
										></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field
											outlined
											dense
											label="Telefonnummer der Kontaktperson"
											prepend-icon="mdi-phone"
											color="secondary"
											v-model="updatedClientData.tel"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12">Anschrift des Unternehmens</v-col>
									<v-col cols="12">
										<v-text-field
											outlined
											dense
											label="Straße + Hausnummer"
											prepend-icon="mdi-city"
											v-model="updatedClientData.adresse.str"
										></v-text-field>
									</v-col>

									<v-col cols="7">
										<v-text-field
											outlined
											dense
											label="Ort"
											prepend-icon="mdi-city"
											v-model="updatedClientData.adresse.ort"
										></v-text-field>
									</v-col>
									<v-col cols="4" offset="1">
										<v-text-field
											outlined
											dense
											label="PLZ"
											prepend-icon="mdi-postage-stamp"
											v-model="updatedClientData.adresse.plz"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											outlined
											dense
											label="Adresszusatz"
											prepend-icon="mdi-dots-horizontal-circle"
											v-model="updatedClientData.adresse.zusatz"
										></v-text-field>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-row justify="center">
								<v-btn @click="step++" color="secondary">
									Speichern und Weiter
								</v-btn>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
				<v-stepper-step step="4">
					Info: Steuerarten
					<small>GewSt, KSt, etc.</small>
				</v-stepper-step>
				<!------------------------- STEP 4 - Fokus -------------------------------------->
				<v-stepper-content step="4">
					<v-card>
						<v-card-title>Steuerarten</v-card-title>
						<v-card-subtitle>
							Die folgenden Steuerarten hat Dein Steuerberater bereits vordefiniert und diese können direkt im
							Anschluss bearbeitet werden.
							<p>
								Dein Steuerberater kann beliebig viele andere Steuerarten zur Bearbeitung hinzufügen. Wenn weitere
								Bearbeitungen erwünscht sind, kannst Du ihm im Anschluss eine Nachricht schreiben.
							</p>
						</v-card-subtitle>
						<v-divider></v-divider>
						<v-card-text>
							<v-list dense v-if="updatedClientData">
								<v-list-item v-for="(type, id) in updatedClientData.categories" :key="id">
									<v-list-item-icon>
										<v-icon>mdi-text-box-multiple</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title v-text="type.name"></v-list-item-title>
										<v-list-item-subtitle class="secondary--text caption">{{ type.year }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list>
							<!-- <v-row align="center" justify="start">
								<v-col v-for="(selection, i) in updatedClientData.categories" :key="selection.name" class="shrink">
									<v-chip close @click:close="updatedClientData.categories.splice(i, 1)" color="secondary">
										<v-icon left>mdi-check</v-icon>
										{{ selection.name }}
									</v-chip>
								</v-col>
								<v-col>
									<v-list dense>
										<template v-for="item in categories">
											<v-list-item
												v-if="!updatedClientData.categories.includes(item)"
												:key="item.name"
												@click="updatedClientData.categories.push(item)"
												dense
											>
												<v-list-item-avatar>
													<v-icon v-text="item.icon" small></v-icon>
												</v-list-item-avatar>
												<v-list-item-title v-text="item.name" class="text-caption"></v-list-item-title>
											</v-list-item>
										</template>
									</v-list>
								</v-col>
							</v-row> -->
						</v-card-text>
						<v-card-actions>
							<v-row justify="center">
								<v-btn @click="step++" color="secondary">
									Zum letzten Schritt
								</v-btn>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
				<v-stepper-step step="5" editable>Abschluss</v-stepper-step>
				<v-stepper-content step="5">
					<v-card>
						<v-card-title>Fast fertig!</v-card-title>
						<v-card-subtitle>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam enim recusandae incidunt aliquam
							voluptatem fugiat, vitae, laborum deserunt est suscipit delectus, adipisci cupiditate molestias quo in
							nemo accusamus ipsum numquam id quidem sit ut? Ea nobis unde aut asperiores ad, rem, minima quis,
							accusantium recusandae debitis tempore quidem et iusto.
						</v-card-subtitle>

						<v-card-actions>
							<v-row justify="center">
								<v-col cols="12">
									<v-checkbox
										color="success"
										label="Ich stimme zu"
										v-model="updatedClientData.consent_given"
									></v-checkbox>
								</v-col>

								<v-btn color="success" :disabled="!updatedClientData.consent_given" @click="finilizeClientData">
									Bearbeitung abschließen
								</v-btn>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
			</v-stepper>
		</v-card>
	</div>
</template>

<script>
export default {
	methods: {
		signup() {
			this.$store.dispatch("signup", {
				email: this.userSignup.email,
				password: this.userSignup.password,
				name: this.userSignup.name,
				invitedBy: this.clientData.owner,
				type: "user",
				linkedClient: this.clientData.companySlug,
			})
		},

		updateClient() {
			this.clientData.taxwizard.started = true
			this.clientData.taxwizard.started_Date = new Date()
			this.clientData.taxwizard.link_visited = true
			this.loading = true
			this.$store.dispatch("updateClient", this.clientData)
			this.loading = false
		},

		linkVisited() {
			this.$store.dispatch("updateClientLink", this.clientData.companySlug)
			this.step++
		},

		async taxwizard_signup() {
			console.log("Signup für User")
			this.loading = true
			const response = await this.$store.dispatch("checkUser", {
				email: this.userSignup.email,
			})
			this.loading = false
			if (!response) {
				this.signup()
				this.updateClient()
			} else {
				console.log("User existiert bereits")
			}
			//   this.duplicateClientData()
			this.step++
		},

		async finilizeClientData() {
			this.updatedClientData.taxwizard.finished = true
			this.updatedClientData.taxwizard.finished_when = new Date()
			this.loading = true
			await this.$store.dispatch("updateClient", this.updatedClientData)
			this.loading = false
			this.$router.push("/")
		},

		duplicateClientData() {
			console.log(this.updatedClientData)
			this.updatedClientData = {
				...this.clientData,
				tel: null,
				consent_given: null,
			}
		},

		test() {
			console.log(this.clientData)
		},
	},

	props: {
		clientData: null,
	},
	data() {
		return {
			step: 1,
			loading: false,
			userSignup: {
				email: null,
				name: {
					firstName: null,
					lastName: null,
				},
				password: null,
			},

			emailRules: [(v) => !!v || "Bitte E-Mail eingeben"],
			rePassword: null,
			items: [
				{
					name: "Körperschaftssteuer",
					icon: "mdi-alarm-check",
				},
				{
					name: "Gewerbesteuer",
					icon: "mdi-alarm-check",
				},
				{
					name: "Umsatzsteuer",
					icon: "mdi-alarm-check",
				},
			],
			updatedClientData: {
				...this.clientData,
				tel: null,
				consent_given: null,
				adresse: {
					str: null,
					ort: null,
					plz: null,
					zusatz: null,
				},
			},
			checkbox: null,
		}
	},

	computed: {
		passwordConfirmationRule() {
			return () => this.userSignup.password === this.rePassword || "Password must match"
		},

		categories() {
			let categories = this.items
			//   for (var i = 0; i < this.clientData.categories.length; i++) {
			//     if (categories.includes(this.clientData.categories[i])) {
			//       console.log(this.clientData.categories[i])
			//     }
			//   }
			for (var i = 0; i < this.clientData.categories.length; i++) {
				for (var ii = 0; ii < categories.length; ii++) {
					if (categories.includes(this.clientData.categories[i])) {
						//
					}
				}
			}

			return categories
		},
	},
}
</script>

<template>
    <div>
        <v-row justify="center">
            <v-col
                lg="9"
                md="9"
                sm="10"
            >
                <center>---- STEUERBERATER BANNER ODER ÄHNLICHES ----- </center>
                <WizardComp v-if="currentClient" :clientData="currentClient"/>
            </v-col>
        </v-row>
        
        <p></p>
        Route-Details: 
        <p>LinkId: {{ this.$route.params.Id }}</p>
        <p>Client Slug: {{ this.$route.params.companyId }}</p>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import WizardComp from '@/components/taxwizard/WizardComp'
export default {
  created () {
      this.fetchClient()
  },
  methods: {
      async fetchClient() {
          this.currentClient = await this.$store.dispatch('fetchClient', this.$route.params.companyId)
          //console.log("Client fetched --> ", this.currentClient)
          if(this.currentClient) {
              if(this.currentClient.taxwizard.linkId == this.$route.params.Id) {
                //console.log("Passt")
            } else {
                console.log("Passt nicht")
            }
          }
      },
      checkLink() {
          if(this.currentClient.taxwizard.linkId == this.$route.params.Id) {
              console.log("Passt")
          } else {
              console.log("Passt nicht")
          }
      },

      test() {
          console.log(this.currentClient)
      }
  },
  data () {
    return {
        currentClient: null,
    }
  },
    computed: {
        ...mapState(['userProfile','activeClient']),
    },
    components: {
      WizardComp,
    }
}
</script>
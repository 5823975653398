<template>
	<v-card flat>
		<AddFile ref="addFileDialog" @addedFile="addedFile" />
		<EditFile ref="editFileDialog" @updateClient="updateFileAfterEdit" />
		<FileComments ref="commentDialog" />
		<!-- <p><v-btn @click="test2">Test</v-btn></p> -->
		<v-card-title v-if="getActiveClient">Belegmanager - {{ getActiveClient.company }}</v-card-title>
		<v-row>
			<v-col cols="6">
				<v-text-field
					v-model="search"
					label="Dateisuche..."
					dense
					outlined
					clearable
					color="secondary"
					prepend-icon="mdi-magnify"
				></v-text-field>
			</v-col>
			<v-spacer></v-spacer>
			<v-col class="d-flex justify-start" align-self="center" cols="4">
				<v-select
					prepend-icon="mdi-calendar-range"
					:items="yearsForFilter"
					attach
					chips
					label="Jahresfilter"
					outlined
					dense
					clearable
					v-model="filterYears"
					color="secondary"
					small-chips
				>
					<template #selection="{ item }">
						<v-chip color="secondary" small>
							<strong>{{ item }}</strong>
						</v-chip>
					</template>
				</v-select>
			</v-col>
		</v-row>
		<v-data-table
			:items="filteredItems"
			:key="rerender"
			item-key="filename"
			v-model="selectedFiles"
			:headers="headers"
			:items-per-page="-1"
			:search="search"
			:loading="isLoading"
			hide-default-footer
			group-by="categories"
			loading-text="Lese Dateien..."
			no-data-text="Bisher keine Dateien gefunden"
		>
			<template v-slot:top>
				<v-row dense>
					<v-col align-self="center" class="d-flex justify-end">
						<v-btn color="secondary" @click="addFile" elevation="10" :loading="isLoading">
							<v-icon left>mdi-plus-circle</v-icon>
							Dateien hinzufügen
						</v-btn>
					</v-col>
				</v-row>
			</template>
			<template v-slot:[`group.header`]="{ group, headers, isOpen, toggle }">
				<td :colspan="headers.length" class="primary secondary--text font-weight-bold">
					<v-icon @click="toggle" color="secondary" small left>
						{{ isOpen ? "mdi-minus-circle" : "mdi-plus-circle" }}
					</v-icon>
					{{ group.toUpperCase() }}
				</td>
			</template>
			<template v-slot:[`item.filename_original`]="{ item }">
				{{ item.filename_original }}
				<p class="caption mb-0 secondary--text">
					<v-icon small color="secondary">mdi-upload</v-icon>
					{{ item.uploadedBy.name.firstName }}
					{{ item.uploadedBy.name.lastName }} -
					{{ formatTime(item.uploadedWhen) }}
				</p>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn icon :loading="isLoading">
					<v-icon @click="editFile(item)" color="primary">
						mdi-pencil
					</v-icon>
				</v-btn>
				<v-btn icon :loading="isLoading">
					<v-icon color="primary" @click="downloadFile(item)">
						mdi-download
					</v-icon>
				</v-btn>
				<v-btn icon :loading="isLoading">
					<v-icon color="red">
						mdi-delete
					</v-icon>
				</v-btn>
			</template>

			<template v-slot:[`item.comments`]="{ item }">
				<v-btn icon :loading="isLoading">
					<v-icon @click="commentFile(item)" :color="item.comments.length > 0 ? 'secondary' : 'primary'">
						mdi-comment-outline
					</v-icon>
				</v-btn>
				<span class="font-weight-light">{{ item.comments.length }}</span>
			</template>
		</v-data-table>
		<v-row>
			<v-col class="d-flex justify-end">
				<v-btn @click="fetchAllFiles" icon class="mt-12">
					<v-icon color="secondary" large>
						mdi-reload
					</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-snackbar
			v-model="snackbar"
			timeout="4000"
			color="success"
			top
			class="position: relative"
			transition="scroll-y-transition"
		>
			<v-icon>mdi-information</v-icon>
			Datei editiert
		</v-snackbar>
	</v-card>
</template>
<script>
/* eslint-disable no-unused-vars */
import { storage } from "@/firebase"
import { mapGetters } from "vuex"
import AddFile from "./UploadCmp"
import EditFile from "./EditFileCmp"
import FileComments from "./FileComments"

export default {
	mounted() {
		if (!this.getFileList && this.getCurrentUser && this.getActiveClient) {
			this.fetchAllFiles()
		}
	},

	watch: {
		getActiveClient: function(val) {
			this.fetchAllFiles()
		},
	},
	methods: {
		async fetchAllFiles(userID) {
			this.reqData.currentUser = this.getCurrentUser.userID
			this.reqData.currentClient = this.getActiveClient.companySlug
			this.isLoading = true
			await this.$store.dispatch("fetchAllFiles", this.reqData)
			this.isLoading = false
		},

		formatTime(timestamp) {
			let date
			if (!(timestamp instanceof Date)) {
				date = timestamp.toDate()
			} else {
				date = timestamp
			}

			//   let mm = date.getMonth() + 1
			//   let dd = date.getDate()
			//   let yyyy = date.getFullYear()

			let newDate = date.toLocaleDateString("de-DE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
			})

			//   date = dd + "." + mm + "." + yyyy
			return newDate
		},

		addFile() {
			this.$refs.addFileDialog.toggleDialog()
		},

		editFile(item) {
			this.$refs.editFileDialog.toggleDialog()
			this.$refs.editFileDialog.passItem(item)
		},

		commentFile(item) {
			this.$refs.commentDialog.toggleDialog()
			this.$refs.commentDialog.passItem(item)
		},

		updateFileAfterEdit(item) {
			// console.log("Update file after edit")
			this.$store.dispatch("updateFile", item)
			this.snackbar = true
		},

		addedFile() {
			console.test("jawoll")
		},
		// TEST DOWNLOAD --------------------
		async downloadFile(item) {
			this.isLoading = true
			await this.$store.dispatch("getDownloadURL", item)
			this.isLoading = false
		},
		// -------------------------------------

		test(item) {},
	},
	computed: {
		...mapGetters(["getCurrentUser", "getFileList", "getActiveClient"]),

		filteredItems() {
			if (this.getFileList) {
				return this.getFileList.filter((i) => {
					return !this.filterYears || i.relevantYears === this.filterYears
				})
			}
			return undefined
		},
	},
	data() {
		return {
			reqData: {
				currentUser: null,
				currentClient: null,
			},
			headers: [
				{
					text: "Dateiname",
					value: "filename_original",
					class: "secondary--text",
				},
				{
					text: "Jahr",
					value: "relevantYears",
					class: "secondary--text",
					groupable: false,
				},
				{
					text: "Kommentare",
					value: "comments",
					class: "secondary--text",
					groupable: false,
				},
				{
					text: "Aktionen",
					value: "actions",
					class: "secondary--text",
					groupable: false,
				},
			],
			yearsForFilter: ["2015", "2016", "2017", "2018", "2019", "2020", "2021"],
			isLoading: false,
			selectedFiles: [],
			filterYears: null,
			search: null,
			snackbar: null,

			currentFileList: this.getFileList,
			rerender: 1,
		}
	},
	components: { AddFile, EditFile, FileComments },
}
</script>

<template>
	<v-dialog
		v-model="dialog"
		persistent
		:fullscreen="$vuetify.breakpoint.xs"
		max-width="80%"
		transition="dialog-bottom-transition"
		scrollable
	>
		<v-card flat min-height="400px">
			<v-card-title>
				<span>Dokumente hochladen</span><v-spacer></v-spacer>
				<v-btn icon small @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider></v-divider>
			<v-card-text>
				<v-row>
					<v-col cols="6">
						<v-card-subtitle title="TEST">Eine oder mehrere Dateien auswählen:</v-card-subtitle>
						<v-file-input
							accept="image/*,.pdf"
							label="Auswahl.."
							v-model="currentFiles"
							dense
							outlined
							multiple
							@change="mountInformation"
							ref="files"
							@click:clear="clearCurrentFiles"
						>
							<template v-slot:selection="{ index, text }">
								<v-chip v-if="index < 2" color="secondary" label small>{{ text }}</v-chip>
								<span v-else-if="index === 2" class="overline grey--text max-2"
									>+ {{ currentFiles.length - 2 }} Dateien</span
								>
							</template>
						</v-file-input>
						<v-switch
							v-model="globalAttr"
							v-if="currentFiles"
							inset
							color="secondary"
							:label="globalAttr ? 'Attribute für alle Dateien gleichzeitig vergeben' : 'Attribute einzeln vergeben'"
						></v-switch>
						<v-list dense :disabled="globalAttr">
							<v-list-item-group color="secondary" v-model="selectedFile">
								<v-list-item v-for="(file, id) in currentFiles" :key="file.name" :value="id">
									<v-list-item-icon>
										<v-icon>mdi-file</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title v-text="file.name"></v-list-item-title>
										<v-list-item-subtitle class="secondary--text caption"
											>{{ sizeInKB(file.size) }} KB</v-list-item-subtitle
										>
									</v-list-item-content>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-col>
					<v-divider vertical></v-divider>
					<!------------------- DIE RECHTE SEITE -->
					<v-col v-if="currentFiles">
						<v-card-subtitle
							v-text="globalAttr ? 'Globale Attribute vergeben' : 'Attribute für diese Datei vergeben'"
						></v-card-subtitle>
						<v-alert
							dense
							outlined
							icon="mdi-information"
							class="secondary--text"
							v-if="!isFileSelected() && !globalAttr"
							>Bitte eine Datei auswählen</v-alert
						>
						<v-text-field
							outlined
							dense
							v-if="isFileSelected() && !globalAttr"
							v-model="filesToUpload[selectedFile].filename_original"
							label="Dateiname"
							prepend-icon="mdi-file-document-outline"
						></v-text-field>
						<v-select
							:items="categories"
							v-model="globalCategories"
							outlined
							dense
							v-if="globalAttr"
							@change="categorieChange"
							label="Globale Kategorie (für alle Dateien)"
							prepend-icon="mdi-form-select"
						>
						</v-select>
						<v-select
							:items="categories"
							v-model="filesToUpload[selectedFile].categories"
							outlined
							dense
							v-if="isFileSelected() && !globalAttr"
							label="Kategorie für diese Datei"
							prepend-icon="mdi-form-select"
						>
						</v-select>
						<v-select
							outlined
							dense
							:items="years"
							v-model="globalYear"
							v-if="globalAttr"
							@change="yearChange"
							label="Relevantes Jahr (für alle Dateien)"
							prepend-icon="mdi-calendar-range"
						></v-select>
						<v-select
							:items="years"
							v-model="filesToUpload[selectedFile].relevantYears"
							outlined
							dense
							v-if="isFileSelected() && !globalAttr"
							label="Relevantes Jahr für diese Datei"
							prepend-icon="mdi-calendar-range"
						>
						</v-select>
						<v-textarea
							outlined
							v-model="filesToUpload[selectedFile].comment"
							clearable
							label="Kommentar (optional)"
							v-if="isFileSelected() && !globalAttr"
							prepend-icon="mdi-comment"
							hint="Optional - Etwas relevantes, was zu dieser Datei noch wichtig ist"
						></v-textarea>
						<v-textarea
							v-model="globalComment"
							outlined
							clearable
							label="Kommentar"
							v-if="globalAttr && currentFiles"
							prepend-icon="mdi-comment"
							hint="Optional - Etwas relevantes, was zu diesen Dateien noch wichtig ist"
							@blur="commentChange"
						></v-textarea>
					</v-col>
				</v-row>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-btn @click="closeDialog" color="primary">Schließen</v-btn><v-spacer></v-spacer
				><v-btn @click="uploadFile" color="secondary" :loading="isLoading">Dateien hochladen</v-btn>
				<!-- <v-spacer></v-spacer><v-btn @click="test">Test Button</v-btn> -->
			</v-card-actions>
			<v-snackbar v-model="snackbar" timeout="5000" color="success">
				<v-icon>mdi-information</v-icon>
				Dateien erfolgreich hochgeladen
			</v-snackbar>
		</v-card>
	</v-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
import { storage } from "@/firebase"
import { mapGetters } from "vuex"
export default {
	watch: {
		globalAttr: function() {
			if (this.globalAttr) {
				this.selectedFile = {}
			}
		},
	},
	methods: {
		mountInformation() {
			this.filesToUpload = []
			this.currentFiles.forEach((file) => {
				this.filesToUpload.push({
					file: file,
					comment: null,
					filename:
						this.getActiveClient.companySlug +
						new Date().getTime() +
						Math.random()
							.toString(36)
							.substr(2, 3),
					filename_original: file.name,
					filesize: file.size,
					uploadedBy: this.getCurrentUser,
					currentClient: this.getActiveClient.companySlug,
					uploadedWhen: new Date(),
					owner: this.getActiveClient.owner,
				})
			})
		},

		// Dispatch an die UploadFile Action im Store
		async uploadFile() {
			if (this.currentFiles) {
				// this.uploadSuccessfull = this.$store.dispatch(
				//   "uploadFiles",
				//   this.filesToUpload
				// )
				this.isLoading = true
				this.uploadSuccessfull = await this.$store.dispatch("uploadAllFiles", this.filesToUpload)
				// Timeline befüllen
				this.timeline = {
					entry: {
						type: "files-added",
						date: new Date(),
						addedBy: this.getCurrentUser.name,
						number: this.filesToUpload.length,
						files: [],
					},
					companySlug: this.getActiveClient.companySlug,
				}

				this.filesToUpload.forEach((item, index) => {
					const data = {
						filename: item.filename_original,
						size: item.filesize,
					}

					this.timeline.entry.files.push(data)
				})
				await this.$store.dispatch("addTimelineEntry", this.timeline)
				this.timeline = {}

				this.currentFiles = null
				this.globalCategories = null
				this.globalYear = null
				this.globalComment = null
				this.isLoading = false
				this.snackbar = true
			}
		},

		categorieChange() {
			this.filesToUpload.forEach((file) => {
				file.categories = this.globalCategories
			})
		},

		yearChange() {
			this.filesToUpload.forEach((file) => {
				file.relevantYears = this.globalYear
			})
		},

		commentChange() {
			this.filesToUpload.forEach((file) => {
				file.comment = this.globalComment
			})
		},

		sizeInKB(size) {
			return (size / 1024).toFixed(2)
		},

		getFileName(file) {
			if (file && typeof file !== "undefined") {
				return file.name
			}
		},

		clearCurrentFiles() {
			console.log("currentFiles gecleared")
			this.currentFiles = null
		},

		test() {
			console.log(this.currentFiles)
		},

		toggleDialog() {
			this.dialog = !this.dialog
		},

		closeDialog() {
			this.currentFiles = null
			this.dialog = !this.dialog
		},

		isFileSelected() {
			if (typeof this.selectedFile === "object") {
				if (Object.keys(this.selectedFile).length === 0 && this.selectedFile.constructor === Object) {
					return null
				} else {
					return true
				}
			}
			if (typeof this.selectedFile === "number") {
				return true
			}
		},
	},
	data() {
		return {
			filesData: {
				files: null,
				filename: null,
				currentClient: null,
				categories: null,
			},
			uploadSuccessfull: null,
			isLoading: false,
			currentFiles: null,
			filesToUpload: [],
			selectedFile: {},
			globalCategories: null,
			globalYear: null,
			globalComment: null,
			snackbar: false,
			timeline: {},

			globalAttr: true,
			categories: [
				"Jahresabschluss",
				"Einkommenssteuer",
				"Lohn-/Gehaltsabrechnung",
				"Finanzamt Korrespondenz",
				"Betriebliche Steuererklärung",
				"Allgemeine Belege",
				"Sonstiges",
			],
			years: ["2017", "2018", "2019", "2020", "2021"],
			dialog: null,
		}
	},
	computed: {
		...mapGetters(["getCurrentUser", "getActiveClient"]),
	},
}
</script>

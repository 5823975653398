<template>
	<div>
		<v-navigation-drawer app :bottom="$vuetify.breakpoint.smAndDown" v-model="drawer" floating>
			<v-sheet class="pl-2 pt-2 sheetImage" color="primary">
				<v-list-item>
					<v-list-item-avatar size="48">
						<v-img src="https://image.freepik.com/free-vector/man-profile-cartoon_18591-58482.jpg"></v-img>
					</v-list-item-avatar>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title v-if="getCurrentUser" class="title white--text">
							{{ getCurrentUser.name.firstName }}
							{{ getCurrentUser.name.lastName }}
						</v-list-item-title>
						<v-list-item-subtitle class="secondary--text font-weight-bold">
							{{ getCurrentUser.email }}
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action><v-icon color="secondary" small>mdi-cog</v-icon></v-list-item-action>
				</v-list-item>
			</v-sheet>

			<v-divider></v-divider>

			<v-list nav dense outlined>
				<v-list-item-group active-class="secondary--text itemBorder">
					<v-list-item-subtitle class="mb-4 font-weight-light">
						Allgemein
					</v-list-item-subtitle>
					<v-list-item to="/">
						<v-list-item-icon>
							<v-icon>mdi-monitor-dashboard</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Dashboard</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item to="/files" v-if="!isTypeStb">
						<v-list-item-icon>
							<v-icon>mdi-cloud-upload</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Belegmanager</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item to="/clients" v-if="isTypeStb">
						<v-list-item-icon>
							<v-icon>mdi-file-cabinet</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Mandanten</v-list-item-title>
							<small class="font-weight-light">Übersicht aller Mandanten</small>
						</v-list-item-content>
					</v-list-item>

					<v-list-item to="/taxwizard">
						<v-list-item-icon>
							<v-icon>mdi-wizard-hat</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Steuer Wizard</v-list-item-title>
							<small class="font-weight-light">Übersicht aller Mandanten</small>
						</v-list-item-content>
					</v-list-item>

					<v-list-item to="/chat" v-if="!isTypeStb">
						<v-list-item-icon>
							<v-icon>mdi-forum</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Nachrichten</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item-group v-if="activeClient && isTypeStb" active-class="secondary--text itemBorder">
						<v-list-item-subtitle class="my-4 font-weight-light">
							{{ activeClient.company }}
							<!-- <p class="caption mb-0">Aktiv</p> -->
						</v-list-item-subtitle>
						<v-list-item :to="{ path: '/clientdetails/' + activeClient.companySlug }" exact>
							<v-list-item-icon>
								<v-icon>mdi-domain</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Details Mandant</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item :to="{ path: '/clientdetails/' + activeClient.companySlug + '/steuerwizard' }">
							<v-list-item-icon>
								<v-icon>mdi-wizard-hat</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Steuerwizard</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item :to="{ path: '/clientdetails/' + activeClient.companySlug + '/files' }">
							<v-list-item-icon>
								<v-icon>mdi-cloud-upload</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Belegmanager</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item to="/chat">
							<v-list-item-icon>
								<v-icon>mdi-forum</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Nachrichten</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list-item-group>
				<v-btn block text color="error" class="mt-16" @click="userLogout">Logout</v-btn>
			</v-list>
			<div class="mt-6">
				<v-list dense color="grey lighten-3" v-if="false">
					<v-subheader inset>Admin console</v-subheader>
					<v-list-item>
						<v-btn color="error" text to="/chat" block>Chat</v-btn>
					</v-list-item>
					<v-list-item to="/Login">
						<v-list-item-icon>
							<v-icon>mdi-login</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Login</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :to="{ path: 'taxwizard/test-mandant/7s3swqfcpehs1aa2k09zwm/' }">
						<v-btn color="warning" text>SteuerWizard-Link</v-btn>
					</v-list-item>
					<v-list-item>
						<v-btn color="info" @click="currentUser" text>Current user</v-btn>
					</v-list-item>
					<v-list-item>
						<v-btn color="error" text @click="userLogout">Logout</v-btn>
					</v-list-item>
				</v-list>
			</div>
		</v-navigation-drawer>
	</div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { projectAuth } from "@/firebase"
import { mapState, mapGetters } from "vuex"
import Clientnav from "@/components/Clientnav.vue"

export default {
	created() {
		this.$store.commit("setLastVisit", this.$cookies.get("lastVisit"))
	},
	methods: {
		currentUser() {
			console.log(this.getCurrentUser)
		},

		userLogout() {
			this.$store.dispatch("logout")
		},
		toggleDrawer() {
			this.drawer = !this.drawer
		},
	},
	data: () => ({
		drawer: null,
	}),
	name: "Navbar",

	computed: {
		...mapState(["userProfile", "activeClient"]),
		...mapGetters(["getCurrentUser", "isTypeStb"]),
	},

	components: {
		Clientnav,
	},
}
</script>

<style>
.sub_div {
	position: absolute;
	bottom: 0px;
}
.itemBorder {
	border: 1px solid;
}
.sheetImage {
	background-image: url("~@/assets/background.png");
	background-repeat: repeat;
}
</style>

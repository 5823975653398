<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.xs"
    max-width="60%"
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card flat min-height="400px" v-if="file">
      <v-card-title>
        Datei editieren - '{{ updatedFile.filename_original }}''
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card-subtitle
          >Die Datei wurde von
          <strong
            >{{ file.uploadedBy.name.firstName }}
            {{ file.uploadedBy.name.lastName }}</strong
          >
          am
          <strong>{{ formatTime(file.uploadedWhen) }} </strong
          >hochgeladen.</v-card-subtitle
        >
        <v-row>
          <v-col>
            <v-text-field
              outlined
              color="secondary"
              dense
              v-model="updatedFile.filename_original"
              label="Dateiname"
              prepend-icon="mdi-file-document-outline"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          ><v-col>
            <v-select
              :items="categories"
              color="secondary"
              v-model="updatedFile.categories"
              outlined
              dense
              label="Kategorie"
              prepend-icon="mdi-form-select"
            >
            </v-select></v-col
          ><v-col cols="4">
            <v-select
              :items="years"
              v-model="updatedFile.relevantYears"
              color="secondary"
              outlined
              dense
              label="Jahr"
              prepend-icon="mdi-calendar-range"
            ></v-select></v-col
        ></v-row>
        <v-row
          ><v-col>
            <v-textarea
              v-model="updatedFile.comment"
              color="secondary"
              outlined
              clearable
              label="Kommentar"
              prepend-icon="mdi-comment"
              hint="Optional - Etwas relevantes, was zu diesen Dateien noch wichtig ist"
            ></v-textarea></v-col
        ></v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="closeDialog" color="primary">Abbrechen</v-btn
        ><v-spacer></v-spacer
        ><v-btn @click="updateFile" color="secondary"
          >Datei aktualisieren</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog
    },

    passItem(item) {
      this.file = item
      Object.assign(this.updatedFile, item)
    },

    updateFile() {
      if (!(JSON.stringify(this.updatedFile) === JSON.stringify(this.file))) {
        this.updatedFile.lastUpdated = new Date()
        this.updatedFile.lastUpdatedBy = this.getCurrentUser
        this.$emit("updateClient", this.updatedFile)
      }
      this.closeDialog()
    },

    closeDialog() {
      this.updatedFile = {}
      this.dialog = !this.dialog
    },

    formatTime(timestamp) {
      let date
      if (!(timestamp instanceof Date)) {
        date = timestamp.toDate()
      } else date = timestamp

      let newDate = date.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      return newDate
    },

    test() {},
  },
  data() {
    return {
      dialog: null,
      file: null,
      updatedFile: {},
      categories: [
        "Jahresabschluss",
        "Einkommenssteuer",
        "Lohn-/Gehaltsabrechnung",
        "Finanzamt Korrespondenz",
        "Betriebliche Steuererklärung",
        "Allgemeine Belege",
        "Sonstiges",
      ],
      years: ["2017", "2018", "2019", "2020", "2021"],
    }
  },

  computed: {
    ...mapGetters(["getCurrentUser"]),
  },
}
</script>

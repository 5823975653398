export default {
	// fetchUserProfile(state) {
	//   return state.userProfile
	// },
	// getCurrentUser(state) {
	//   return state.userProfile
	// },

	getClientList(state) {
		return state.clientList
	},

	getActiveClient(state) {
		return state.activeClient
	},

	getLastVisit(state) {
		return state.lastVisit
	},
}

<template>
	<div>
		<v-row justify="center">
			<v-col lg="5" md="7" sm="9">
				<LoginForm v-if="!showSignup" />
				<!-- <SignupForm v-if="showSignup" /> -->
				<p v-if="showSignup">
					<v-alert color="#82BAAE" icon="mdi-wizard-hat">
						Anmeldungen sind zur Zeit deaktiviert. Wenn Du bereits jetzt schon teilnehmen möchtest, kontaktiere uns
						einfach unter: info@sumer.ai
					</v-alert>
				</p>
				<v-divider class="mb-5"></v-divider>
				<v-alert prominent color="primary" dense type="info" icon="mdi-account">
					<v-row align="center">
						<v-col class="grow">
							Noch keinen Account?
						</v-col>
						<v-col class="shrink">
							<v-btn outlined color="secondary" @click="toggleSignup">
								{{ btn_account_text }}
							</v-btn>
						</v-col>
					</v-row>
				</v-alert>
			</v-col>
		</v-row>
	</div>
</template>

<script>
/* eslint-disable no-unused-vars */
import store from "vuex"
import SignupForm from "@/components/auth/SignupForm.vue"
import LoginForm from "@/components/auth/LoginForm.vue"
export default {
	watch: {},
	components: {
		LoginForm,
		SignupForm,
	},
	methods: {
		toggleSignup() {
			this.showSignup = !this.showSignup
			if (this.showSignup) {
				this.btn_account_text = "Zurück zum Login"
			} else {
				this.btn_account_text = "Account erstellen"
			}
		},
	},

	data() {
		return {
			showSignup: false,
			btn_account_text: "Account erstellen",
		}
	},
}
</script>

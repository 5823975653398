<template>
	<v-card flat max-width="100%" v-if="getTimeline">
		<v-card-title class="subtitle-2">Timeline {{ getItemLimit }}/{{ getTimeline.length }} Events</v-card-title>
		<v-timeline align-top v-if="getTimeline.length > 0" dense>
			<v-timeline-item
				v-for="(item, index) in limitedTimeline"
				:key="index"
				:color="getProps(item).color"
				:icon="getProps(item).icon"
				fill-dot
				:small="getProps(item).smallIcon"
				class="pl-0"
			>
				<v-card elevation="2" max-width="95%" :color="getProps(item).color" class="white--text">
					<v-card-title class="caption py-1">{{ getProps(item).name }}</v-card-title>
					<v-card-text class="white pt-1">
						<truncate
							clamp="Details"
							less="...Weniger"
							action-class="secondary--text"
							:length="20"
							type="html"
							text="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem hic accusantium, pariatur saepe
						possimus commodi cum quam recusandae ipsum earum.<br>"
						></truncate>
					</v-card-text>
					<v-card-actions class="py-0 caption grey lighten-3 text--primary"
						>{{ formatTime(item.date) }} -- {{ item.addedBy.firstName }} {{ item.addedBy.lastName }}</v-card-actions
					>
				</v-card>
			</v-timeline-item>
		</v-timeline>
		<v-btn @click="raiseItemLimit">Show 5 more...</v-btn>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex"
// eslint-disable-next-line no-unused-vars
import truncate from "vue-truncate-collapsed"
export default {
	components: { truncate },
	methods: {
		getProps(val) {
			let entry = {}
			switch (val.type) {
				case "file-commented": {
					entry.color = "green darken-2"
					entry.icon = "mdi-message"
					entry.smallIcon = true
					entry.name = "Kommentar in: " + val.filename
					return entry
				}
				case "files-added": {
					entry.color = "red darken-2"
					entry.icon = "mdi-cloud-upload"
					entry.smallIcon = false
					entry.name = "Es wurde(n) " + val.number + " Datei(en) hochgeladen"
					return entry
				}
				default: {
					entry.color = "grey"
					entry.icon = "mdi-checkbox-blank-circle"
					entry.name = "Timeline entry - " + val.type
					return entry
				}
			}
		},

		raiseItemLimit() {
			this.itemLimit = this.itemLimit + 5
			if (this.itemLimit > this.getTimeline.length) this.itemLimit = this.getTimeline.length
		},

		formatTime(timestamp) {
			let date
			if (!(timestamp instanceof Date)) {
				date = timestamp.toDate()
			} else date = timestamp

			let newDate = date.toLocaleDateString("de-DE", {
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
			})
			return newDate
		},
	},
	data() {
		return {
			itemLimit: 5,
		}
	},
	computed: {
		...mapGetters(["getTimeline"]),
		limitedTimeline() {
			return this.itemLimit ? this.getTimeline.slice(0, this.itemLimit) : this.getTimeline
		},

		getItemLimit() {
			if (this.itemLimit > this.getTimeline.length) {
				return this.getTimeline.length
			} else return this.itemLimit
		},
	},
	name: "Timeline",
}
</script>

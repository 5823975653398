<template>
	<v-app-bar app color="primary" dense clipped-right class="sheetImage" flat>
		<v-app-bar-nav-icon class="secondary--text" @click="toggleNavbar"></v-app-bar-nav-icon>
		<v-toolbar-title class="white--text font-weight-bold">Sumer.ai</v-toolbar-title>
		<!-- <ClientAppBar v-if="getActiveClient" /> -->
		<v-spacer></v-spacer>
		<!-- <span v-if="isTypeStb" class="subtitle-2 white--text font-weight-bold mr-2">Aktiver Mandant:</span> -->
		<v-menu
			bottom
			:offset-y="true"
			v-if="getActiveClient"
			transition="slide-y-transition"
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on }">
				<v-btn color="secondary" label text-color="black" v-on="on" small outlined>
					{{ getActiveClient.company }}
				</v-btn>
			</template>
			<v-card>
				<v-card-text>
					<strong>{{ getActiveClient.company }}</strong>
					<p><small>Aktiver Mandant</small></p>

					<v-autocomplete
						dense
						outlined
						hide-details
						flat
						label="Mandanten wechseln"
						:items="getClientList"
						item-text="company"
						v-model="changeClient"
						return-object
					></v-autocomplete>
					<v-row justify="center">
						<v-col cols="12">
							<v-btn small @click="activateClient(changeClient)" block color="secondary">Wechseln</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-menu>
		<v-spacer></v-spacer>
		<v-icon right color="secondary" @click="toggleRightNavbar">mdi-information</v-icon>
		<!-- <v-menu bottom rounded min-width="200px">
			<template v-slot:activator="{ on }">
				<v-btn icon v-on="on" class="mx-auto">
					<v-avatar color="grey" size="36">
						<img src="https://pbs.twimg.com/profile_images/1261075175086456833/uIXjWP3I.jpg" />
					</v-avatar>
				</v-btn>
			</template>
			<v-card>
				<v-list-item-content>
					<div class="mx-auto text-center">
						<v-avatar color="grey">
							<img src="https://pbs.twimg.com/profile_images/1261075175086456833/uIXjWP3I.jpg" />
						</v-avatar>
						<h3>

							{{ getCurrentUser.name.firstName }}
							{{ getCurrentUser.name.lastName }}
						</h3>
						<p class="caption mt-1">{{ getCurrentUser.email }}</p>
						<v-divider class="my-3"></v-divider>
						<v-btn depressed rounded dense small text class="primary">
							<v-icon left small>mdi-account</v-icon>
							Profil
						</v-btn>
						<v-divider class="my-3"></v-divider>
						<v-btn depressed rounded dense small class="warning">
							<v-icon left small>mdi-logout</v-icon>
							Ausloggen
						</v-btn>
					</div>
				</v-list-item-content>
			</v-card>
		</v-menu> -->
		<!-- <v-btn icon class="secondary--text" @click="toggleRightNavbar"><v-icon>mdi-domain</v-icon></v-btn> -->
	</v-app-bar>
</template>

<script>
import { mapGetters } from "vuex"
export default {
	watch: {},
	data() {
		return {
			showNav: true,
			changeClient: null,
		}
	},

	methods: {
		toggleNavbar() {
			this.$emit("toggleNav")
		},

		toggleRightNavbar() {
			this.$emit("toggleRightNav")
		},
		test() {
			console.log(this.changeClient)
		},
		activateClient(item) {
			this.$store.dispatch("updateLastAktiveClient", item)
			this.$store.commit("clearFileList")
			this.$store.commit("clearTimeline")
			this.$store.dispatch("getTimelineForActiveClient", item)
		},
	},
	name: "Appbar",

	computed: {
		...mapGetters(["getCurrentUser", "getActiveClient", "isTypeStb", "getClientList"]),
	},
}
</script>

<style scoped>
.sheetImage {
	background-image: url("~@/assets/background.png");
	background-repeat: repeat;
}
</style>

<template>
	<div v-if="getActiveClient">
		<v-stepper vertical v-model="step" non-linear>
			<v-stepper-step step="1" :complete="taxwizard_data.step1_complete">
				Allgemeine Informationen zum Unternehmen
				<small>Bereits erfasste Informationen bestätigen</small>
			</v-stepper-step>
			<!-- STEP 1 -------------------------------------------------------------->
			<v-stepper-content step="1">
				<v-card :loading="loading">
					<template slot="progress">
						<v-progress-linear color="secondary" height="10" indeterminate></v-progress-linear>
					</template>
					<v-card-text>
						<v-form ref="form_step1">
							<v-row dense>
								<v-col cols="12"></v-col>
								<v-col cols="6">
									<v-text-field
										outlined
										dense
										label="Firmenname"
										prepend-icon="mdi-domain"
										v-model="taxwizard_data.firmenname"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
								<v-col cols="6">
									<v-select
										:items="rechtsformen"
										item-text="name"
										outlined
										dense
										prepend-icon="mdi-bank"
										v-model="taxwizard_data.rechtsform"
										label="Rechtsform des Unternehmens"
										return-object
										validate-on-blur
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Die Rechtsform der Firma</span>
										</v-tooltip>
									</v-select>
								</v-col>
								<v-col cols="6">
									<v-text-field
										outlined
										dense
										label="Vorname Unternehmer"
										prepend-icon="mdi-clipboard-account"
										v-model="taxwizard_data.unternehmer.vorname"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										outlined
										dense
										label="Nachname Unternehmer"
										prepend-icon="mdi-clipboard-account-outline"
										v-model="taxwizard_data.unternehmer.nachname"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12">Anschrift des Unternehmens</v-col>
								<v-col cols="9">
									<v-text-field
										outlined
										dense
										label="Straße + Hausnummer"
										prepend-icon="mdi-city"
										v-model="taxwizard_data.adresse.str"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>

								<v-col cols="6">
									<v-text-field
										outlined
										dense
										label="Ort"
										prepend-icon="mdi-city"
										v-model="taxwizard_data.adresse.ort"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
								<v-col cols="3">
									<v-text-field
										outlined
										dense
										label="PLZ"
										prepend-icon="mdi-postage-stamp"
										v-model="taxwizard_data.adresse.plz"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
								<v-col cols="9">
									<v-text-field
										outlined
										dense
										label="Adresszusatz"
										prepend-icon="mdi-dots-horizontal-circle"
										v-model="taxwizard_data.adresse.zusatz"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12">Kontakt</v-col>
								<v-col cols="7">
									<v-text-field
										outlined
										dense
										label="E-Mail Adresse"
										prepend-icon="mdi-email"
										v-model="taxwizard_data.kontakt.email"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
								<v-col cols="5">
									<v-text-field
										outlined
										dense
										label="Telefon"
										prepend-icon="mdi-cellphone"
										v-model="taxwizard_data.kontakt.tel"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn color="primary" @click="step--">Zurück</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="secondary" @click="saveData(1)" :loading="loading">Speichern und Weiter</v-btn>
					</v-card-actions>
				</v-card>
			</v-stepper-content>
			<!-- STEP 2 -------------------------------------------------------------->
			<v-stepper-step step="2" :complete="taxwizard_data.step2_complete">
				Angaben zum Finanzamt
				<small>z.B. Steuernummer</small>
			</v-stepper-step>
			<v-stepper-content step="2">
				<v-card>
					<template slot="progress">
						<v-progress-linear color="secondary" height="10" indeterminate></v-progress-linear>
					</template>
					<v-card-text>
						<v-form ref="form_step2">
							<v-row dense>
								<v-col cols="12">
									In welchem Bundesland sitzt das Unternehmen und welches ist das zuständige Finanzamt?
								</v-col>
								<v-col cols="6">
									<v-text-field
										outlined
										dense
										label="Bundesland"
										prepend-icon="mdi-city-variant"
										v-model="taxwizard_data.bundesland"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										outlined
										dense
										label="Aktuelles Finanzamt"
										prepend-icon="mdi-bank"
										v-model="taxwizard_data.finanzamt"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
								<v-col cols="12">
									<v-subheader>Identifikationsnummern</v-subheader>
								</v-col>
								<v-col cols="12">
									<v-text-field
										outlined
										dense
										label="Steuernummer"
										prepend-icon="mdi-numeric-1-box"
										v-model="taxwizard_data.steuernummer"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										outlined
										dense
										label="Wirtschafts-Identifikationsnummer"
										prepend-icon="mdi-numeric-2-box"
										v-model="taxwizard_data.wirtschnummer"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										outlined
										dense
										label="Umsatzsteuer-Identifikationsnummer"
										prepend-icon="mdi-numeric-3-box"
										v-model="taxwizard_data.ustid"
									>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn color="primary" @click="step--" :loading="loading">Zurück</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="secondary" @click="saveData" :loading="loading">Weiter</v-btn>
					</v-card-actions>
				</v-card>
			</v-stepper-content>
			<!-- STEP 3 -------------------------------------------------------------->
			<v-stepper-step step="3" :complete="taxwizard_data.step3_complete">
				Gewinnermittlung
				<small>Soweit vorliegend</small>
			</v-stepper-step>
			<v-stepper-content step="3">
				<v-card>
					<template slot="progress">
						<v-progress-linear color="secondary" height="10" indeterminate></v-progress-linear>
					</template>
					<v-card-text>
						<v-form ref="form_step3">
							<v-row dense>
								<v-col cols="10">
									Stehen die Betriebseinnahmen und Betriebsausgaben zur Verfügung?
									<v-radio-group v-model="taxwizard_data.gewinnermittlung.yesno">
										<v-radio label="Ja, die Einnahmen und Ausgaben stehen zur Verfügung" :value="true"></v-radio>
										<v-radio label="Nein" :value="false"></v-radio>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-expand-transition>
								<v-row v-if="taxwizard_data.gewinnermittlung.yesno">
									<v-col cols="12">
										Bitte nach Kenntniss ausfüllen
									</v-col>
									<v-col cols="4">
										<v-text-field
											outlined
											dense
											label="Summe der Betriebseinnahmen"
											v-model="taxwizard_data.gewinnermittlung.betreinnahmen"
										>
											<v-tooltip slot="append" top>
												<template v-slot:activator="{ on, attrs }">
													<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
												</template>
												<span>Der eingetragene Name der Firma</span>
											</v-tooltip>
										</v-text-field>
									</v-col>
									<v-col cols="4">
										<v-text-field
											outlined
											dense
											label="Summe der Betriebsausgaben"
											v-model="taxwizard_data.gewinnermittlung.betrausgaben"
										>
											<v-tooltip slot="append" top>
												<template v-slot:activator="{ on, attrs }">
													<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
												</template>
												<span>Der eingetragene Name der Firma</span>
											</v-tooltip>
										</v-text-field>
									</v-col>
									<v-col cols="4">
										<v-text-field
											outlined
											dense
											label="Gewinn / Verlust"
											prepend-icon="mdi-cash-multiple"
											v-model="taxwizard_data.gewinnermittlung.gewinnverlust"
										>
											<v-tooltip slot="append" top>
												<template v-slot:activator="{ on, attrs }">
													<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
												</template>
												<span>Der eingetragene Name der Firma</span>
											</v-tooltip>
										</v-text-field>
									</v-col>
								</v-row>
							</v-expand-transition>
							<v-row class="mt-6">
								<v-col cols="10">
									Stehen Journal und Betriebswirtschaftliche Auswertung zur Verfügung?
									<v-radio-group v-model="taxwizard_data.betrauswertung">
										<v-radio label="Ja, die Auswertungen stehen zur Verfügung" :value="true"></v-radio>
										<v-radio label="Nein" :value="false"></v-radio>
										<v-tooltip slot="append" top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="secondary" v-bind="attrs" v-on="on">mdi-information</v-icon>
											</template>
											<span>Der eingetragene Name der Firma</span>
										</v-tooltip>
									</v-radio-group>
								</v-col>

								<v-col cols="12" v-if="taxwizard_data.betrauswertung">
									<p class="secondary--text">
										Danke - Die Auswertungen können dann im späteren Dateiupload hochgeladen werden, Deine Antwort wird
										dort berücksichtigt
									</p>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn color="primary" @click="step--" :loading="loading">Zurück</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="secondary" @click="saveData" :loading="loading">Weiter</v-btn>
					</v-card-actions>
				</v-card>
			</v-stepper-content>
			<!-- STEP 4 -------------------------------------------------------------->
			<v-stepper-step step="4" :complete="taxwizard_data.step4_complete">
				Angaben zur Gewerbesteuer
				<small>z.B. Betriebsstätten</small>
			</v-stepper-step>
			<v-stepper-content step="4">
				<v-card>
					<template slot="progress">
						<v-progress-linear color="secondary" height="10" indeterminate></v-progress-linear>
					</template>
					<v-card-text>
						<v-form ref="form_step4">
							<v-row dense>
								<v-col>
									Wurde die Rechtsform in diesem Jahr gewechselt?
									<v-radio-group v-model="taxwizard_data.rechtsformwechsel">
										<v-radio label="Ja, die Rechtsform wurde gewechselt" :value="true"></v-radio>
										<v-radio label="Nein" :value="false"></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-expand-transition>
								<div v-if="taxwizard_data.rechtsformwechsel">
									<v-row dense>
										<v-col cols="12">
											Die Rechtsform wurde geändert - Bitte daher die Werte
											<strong>vor</strong>
											dem Wechsel ergänzen
										</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="8">
											<v-text-field
												outlined
												dense
												label="Steuernummer vor dem Wechsel"
												v-model="taxwizard_data.vor_rechtsformwechsel.steuernummer"
											></v-text-field>
										</v-col>
										<v-col cols="12" sm="6" md="4">
											<v-menu
												v-model="datemenu"
												:close-on-content-click="false"
												:nudge-right="40"
												transition="scale-transition"
												offset-y
												min-width="auto"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														outlined
														dense
														v-model="taxwizard_data.vor_rechtsformwechsel.date"
														label="Datum der Änderung"
														prepend-icon="mdi-calendar"
														readonly
														v-bind="attrs"
														v-on="on"
													></v-text-field>
												</template>
												<v-date-picker
													v-model="taxwizard_data.vor_rechtsformwechsel.date"
													@input="datemenu = false"
													show-current
													locale="de-de"
												></v-date-picker>
											</v-menu>
										</v-col>
									</v-row>
								</div>
							</v-expand-transition>
							<v-row class="mt-4">
								<v-col cols="12">
									Bestanden Betriebsstätten in mehreren gemeinden?
									<v-radio-group v-model="taxwizard_data.btrst_gemeinden">
										<v-radio label="Ja, es bestanden Betriebsstätten in mehreren Gemeinden" :value="true"></v-radio>
										<v-radio label="Nein" :value="false"></v-radio>
									</v-radio-group>
								</v-col>
								<v-col cols="12">
									Erstreckten sich Betriebsstätten über mehrere Gemeinde?
									<v-radio-group v-model="taxwizard_data.btrst_gemeinden2">
										<v-radio label="Ja, Betriebsstätten erstreckten sich über mehrere Gemeinde" :value="true"></v-radio>
										<v-radio label="Nein" :value="false"></v-radio>
									</v-radio-group>
								</v-col>
								<v-col cols="12">
									Wurde eine Betriebsstätte verlegt?
									<v-radio-group v-model="taxwizard_data.btrst_verlegt">
										<v-radio label="Ja, es wurde eine Betriebsstätte verlegt" :value="true"></v-radio>
										<v-radio label="Nein" :value="false"></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn color="primary" @click="step--" :loading="loading">Zurück</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="secondary" @click="saveData" :loading="loading">Weiter</v-btn>
					</v-card-actions>
				</v-card>
			</v-stepper-content>
			<!-- STEP 5 -------------------------------------------------------------->
			<v-stepper-step step="5" :complete="taxwizard_data.step5_complete">
				Weitere Angaben zur Gewerbesteuer
				<small>Speziell für {{ getActiveClient.company }}</small>
			</v-stepper-step>
			<v-stepper-content step="5">
				<v-card>
					<v-card-text>
						Hier noch die Customized form... Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem sed
						facilis, ut voluptate, quaerat esse obcaecati quo voluptates fuga qui assumenda est illo. Ullam debitis
						exercitationem hic doloremque rerum accusantium nihil facilis id beatae perferendis est obcaecati
						corporis non quibusdam dolor molestias nisi, similique excepturi officia quisquam. Perspiciatis
						voluptatibus iure facilis tempore quisquam eos, quasi consequatur fuga unde. Eius, expedita quaerat? Eos
						consequuntur soluta, cupiditate reprehenderit expedita ducimus odit, enim quos dolor cum temporibus
						itaque quam! Obcaecati explicabo voluptatem voluptatibus sint atque! Fugit pariatur nesciunt atque sed
						expedita obcaecati sint numquam deserunt, harum ipsa, ad voluptate odio hic optio qui.
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="step--" :loading="loading">Zurück</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="secondary" @click="step++" :loading="loading">Weiter</v-btn>
					</v-card-actions>
				</v-card>
			</v-stepper-content>
			<!-- STEP 6 -------------------------------------------------------------->
			<v-stepper-step step="6" :complete="taxwizard_data.step6_complete">
				Benötigte Dokumente
				<small>z.B. PDF Upload</small>
			</v-stepper-step>
			<v-stepper-content step="6">
				<v-card></v-card>
			</v-stepper-content>
		</v-stepper>
		<v-divider class="mb-12"></v-divider>
		<v-btn @click="test">Testbutton</v-btn>
		<v-btn @click="saveData">SPEICHERN TEST</v-btn>
	</div>
</template>

<script>
/* eslint-disable no-fallthrough */
import { mapGetters } from "vuex"
import rechtsformen from "@/assets/rechtsformen.json"
export default {
	watch: {
		getCurrentTwData: function() {
			// console.log(this.getCurrentTwData)
			if ("data" in this.getCurrentTwData) {
				this.taxwizard_data = { ...this.getCurrentTwData.data }
			} else {
				this.prefillForm()
			}
		},
	},
	props: {
		activeClient: {
			type: [Object],
			default: null,
			required: false,
		},
		currentUser: {
			type: [Object],
			default: null,
			required: false,
		},
	},
	mounted() {
		this.prefillForm()
		this.loading = true
		this.$store.dispatch("fetchCurrentTwData", this.$route.params.link)
		this.loading = false
	},

	methods: {
		test() {
			console.log(this.taxwizard_data)
		},

		prefillForm() {
			if (this.getActiveClient && this.getCurrentUser) {
				this.taxwizard_data.firmenname = this.getActiveClient.company
				this.taxwizard_data.rechtsform = this.getActiveClient.rechtsform
				this.taxwizard_data.unternehmer.vorname = this.getCurrentUser.name.firstName
				this.taxwizard_data.unternehmer.nachname = this.getCurrentUser.name.lastName
				this.taxwizard_data.kontakt.email = this.getCurrentUser.email
				this.taxwizard_data.kontakt.tel = this.getActiveClient.tel
				this.taxwizard_data.adresse.str = this.getActiveClient.adresse.str
				this.taxwizard_data.adresse.ort = this.getActiveClient.adresse.ort
				this.taxwizard_data.adresse.plz = this.getActiveClient.adresse.plz
				this.taxwizard_data.adresse.zusatz = this.getActiveClient.adresse.zusatz
			}
		},

		async saveData(step) {
			const payload = { ...this.taxwizard_data }
			payload.tlink = this.$route.params.link
			try {
				this.loading = true
				switch (step) {
					case 1:
						this.taxwizard_data.step1_complete = true
					case 2:
						this.taxwizard_data.step2_complete = true
					case 3:
						this.taxwizard_data.step3_complete = true
					case 4:
						this.taxwizard_data.step4_complete = true
					case 5:
						this.taxwizard_data.step5_complete = true
					case 6:
						this.taxwizard_data.step6_complete = true
				}
				await this.$store.dispatch("saveData", payload)
				this.loading = false
				if (this.step < this.maxstep) {
					this.step++
				}
			} catch (e) {
				this.loading = false
				//
			}
		},
	},

	data() {
		return {
			loading: false,
			step: 1,
			maxstep: 6,

			datemenu: false,
			rechtsformen: rechtsformen,

			taxwizard_data: {
				type: "Gewerbesteuer",
				year: 2020,
				firmenname: null,
				step1_complete: false,
				step2_complete: false,
				step3_complete: false,
				step4_complete: false,
				step5_complete: false,
				step6_complete: false,
				rechtsform: null,
				unternehmer: {
					vorname: null,
					nachname: null,
				},
				adresse: {
					str: null,
					ort: null,
					plz: null,
					zusatz: null,
				},
				kontakt: {
					email: null,
					tel: null,
				},
				gewinnermittlung: {
					yesno: null,
					betreinnahmen: null,
					betrausgaben: null,
					gewinnverlust: null,
				},
				bundesland: null,
				finanzamt: null,
				steuernummer: null,
				wirtschnummer: null,
				ustid: null,
				betreinnahmen: null,
				betrauswertung: null,
				rechtsformwechsel: null,
				vor_rechtsformwechsel: {
					datum: null,
					steuernummer: null,
					bundesland: null,
					finanzamt: null,
				},
				btrst_gemeinden: null,
				btrst_gemeinden2: null,
				btrst_verlegt: null,
			},
		}
	},
	computed: {
		...mapGetters(["getActiveClient", "getCurrentUser", "getCurrentTwData"]),
	},
}
</script>

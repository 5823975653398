export default {
  getCurrentUser(state) {
    return state.currentUser
  },

  isLoggedIn(state) {
    return state.isLoggedIn
  },

  isTypeStb(state) {
    if (state.currentUser.type === "stb") {
      return true
    } else {
      return false
    }
  },

  isTypeUser(state) {
    if (state.currentUser.type === "user") {
      return true
    } else {
      return false
    }
  },
}

<template>
  <div>
    <v-card class="ma-2 mx-auto" outlined max-width="100%">
      <v-card-title>Mandanten - Anzahl: {{ clients.length }} </v-card-title>
      <v-expansion-panels>
        <v-expansion-panel v-for="client in clients" :key="client.id">
          <v-expansion-panel-header>
            {{ client.company }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="space-between">
              <v-col> Mandant: {{ client.name }} </v-col>
              <v-col> Rechtsform: {{ client.rechtsform }} </v-col>
              <v-col> Status: {{ client.status }} </v-col>
              <v-btn class="success" @click="setActiveClient(client)"
                >Aktivieren</v-btn
              >
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {
  methods: {
    setActiveClient(client) {
      this.$store.commit("setActiveClient", client)
    },
  },
  data() {
    return {
      clients: [
        {
          id: 1,
          name: "Stefan Kiehne",
          rechtsform: "UG",
          company: "Kiehne Media UG",
          status: "Nicht abgeschlossen",
          telefon: "12345566",
          email: "mail@kiehne.com",
        },
        {
          id: 2,
          name: "Bettina Fischer",
          rechtsform: "AG",
          company: "Glühwein mit Schuss AG",
          status: "Abgeschlossen",
          telefon: "0151 23123 123 123",
          email: "bettina@fischermails.de",
        },
        {
          id: 3,
          name: "Puki",
          rechtsform: "GmbH",
          company: "Pukinauten GmbH",
          status: "Nicht abgeschlossen",
          telefon: "0171 123 1 312",
          email: "puki@kiehne.com",
        },
      ],
    }
  },
  name: "ClientList",
}
</script>
